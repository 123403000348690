import React, { Component } from 'react'
import DetaislHeader from '../../components/detailsHeader/DetailsHeader';

import VideoPlayer from '../../components/liveVideo/VideoPlayer';


interface Props {
  
}
interface State {
  
}

export  default class ProductionLineLive extends Component<Props, State> {
  state = {}

  render() {
    return (
      <div>
        <DetaislHeader>视觉检测</DetaislHeader>
        <VideoPlayer src='https://hls01open.ys7.com/openlive/199cef5b7a05472ca539b3895f5933dd.hd.m3u8' ></VideoPlayer>
      </div>
    )
  }
}



