import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import Request from '../../../api/request';
import API from '../../../api/api';

class QualityCharts extends React.Component<any, any> {
    private getQualityChartsList: Request;
    timer1!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.getQualityChartsList = new Request(API.GET_TODAY_PROCESS_DATA);
        this.state = {
            isShow: false,
            size: '30%',
            getQualityChartsList: {},
            spillGlueCount: 0,//溢胶
            breakGlueCount: 0,//断胶
            polishedTooSmallCount: 0,//面积过小
            overPolishedCount: 0,//超限
            abnormal: 1,
            spillGlueCountName: '溢胶',
            breakGlueCountName: '断胶',
            polishedTooSmallCountName: '打磨面积过小',
            overPolishedCountName: '打磨面积超限'
        }
    }

    getData() {
        this.getQualityChartsList.send().then(res => {
            this.setState({
                getQualityChartsList: res,
            }, () => {
                this.setState({
                    spillGlueCount: this.state.getQualityChartsList.coatingMachineSpillGlueCount + this.state.getQualityChartsList.gelatinizeSpillGlueCount,
                    // spillGlueCount: 2,
                    breakGlueCount: this.state.getQualityChartsList.coatingMachineBreakGlueCount + this.state.getQualityChartsList.gelatinizeBreakGlueCount,
                    // breakGlueCount: 1,
                    polishedTooSmallCount: this.state.getQualityChartsList.polishedTooSmallCount,
                    overPolishedCount: this.state.getQualityChartsList.overPolishedCount,
                }, () => {
                    this.handleData();
                })
            })
        })
    }

    handleData() {
        if (this.state.spillGlueCount) {
            this.setState({
                spillGlueCountName: `溢胶${this.state.spillGlueCount}次`
            })
        } else {
            this.setState({
                spillGlueCountName: `溢胶`
            })
        }

        if (this.state.breakGlueCount) {
            this.setState({
                breakGlueCountName: `断胶${this.state.breakGlueCount}次`
            })
        } else {
            this.setState({
                breakGlueCountName: `断胶`
            })
        }

        if (this.state.polishedTooSmallCount) {
            this.setState({
                polishedTooSmallCountName: `打磨面积过小${this.state.polishedTooSmallCount}次`
            })
        } else {
            this.setState({
                polishedTooSmallCountName: `打磨面积过小`
            })
        }

        if (this.state.overPolishedCount) {
            this.setState({
                overPolishedCountName: `打磨面积超限${this.state.overPolishedCount}次`
            })
        } else {
            this.setState({
                overPolishedCountName: `打磨面积超限`
            })
        }

        if (this.state.spillGlueCount === 0 && this.state.breakGlueCount === 0 && this.state.polishedTooSmallCount === 0 && this.state.overPolishedCount === 0) {
            this.setState({
                isShow: false,
                size: '30%',
                abnormal: 1
            }, () => {
                this.generatingCharts();
            })
        } else {
            this.setState({
                isShow: true,
                size: '50%',
                abnormal: null
            }, () => {
                this.generatingCharts();
            })
        }
    }

    generatingCharts() {

        if (document.getElementById('cardMain2-qualitycharts') != null) {
            let myChart = echarts.init(document.getElementById('cardMain2-qualitycharts') as HTMLDivElement);

            myChart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c} ({d}%)'
                },
                legend: {
                    show: !this.state.isShow,
                    orient: 'vertical',
                    right: 'right',
                    padding: [30, 25, 0, 0],
                    data: ['打磨面积超限', '打磨面积过小', '溢胶', '断胶', '其他'],
                    textStyle: {
                        color: '#fff',
                        fontSize: 16,
                    }
                },
                color: ['#79DAFF', '#FFF1B8', '#BAE7FF', '#FFA39E', '#8DAA9E', '#bfbfbf'],
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['50%', '60%'],
                        center: [this.state.size, '50%'],
                        // avoidLabelOverlap: false,
                        label: {
                            show: this.state.isShow,
                            // position: 'center'
                            color: '#fff',
                        },
                        emphasis: {
                            label: {
                                show: this.state.isShow,
                                // fontSize: 20,    
                                color: '#EFAD1F',
                                fontWeight: 'bold',
                            }
                        },
                        labelLine: {
                            show: this.state.isShow
                        },
                        data: [
                            { value: this.state.overPolishedCount, name: this.state.overPolishedCountName },
                            { value: this.state.polishedTooSmallCount, name: this.state.polishedTooSmallCountName },
                            { value: this.state.spillGlueCount, name: this.state.spillGlueCountName },
                            { value: this.state.breakGlueCount, name: this.state.breakGlueCountName },
                            { value: 0, name: '其他' },
                            { value: this.state.abnormal, name: '无异常' },
                        ]
                    }
                ]
            });
        }

    }

    componentDidMount() {
        this.getData();

        this.timer1 = setInterval(() => {
            this.getData();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1);
        }
    }

    render() {
        return (
            <div id="cardMain2-qualitycharts" style={{ width: 400, height: 250 }}></div>
        );
    }
}

export default QualityCharts;