import React from 'react';
import echarts from 'echarts';
import Request from '../../../api/request';
import API from '../../../api/api';

class ProductionCharts extends React.Component<any, any> {
    private getMonthProcessDataList: Request;
    timer1!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.getMonthProcessDataList = new Request(API.GET_HISTORY_PROCESS_BY_DAY_COUNT);
        this.state = {
            monthDay: [],
            monthProcessData: [],
            monthProcessDataList: [],
            id: this.props.id
        }
    }

    getData() {
        this.getMonthProcessDataList.send().then(res => {
            this.setState({
                monthProcessDataList: res
            }, () => {
                let monthDay: any[] = [];
                let monthProcessData: any[] = [];
                this.state.monthProcessDataList.map((item: any) => {
                    let day = item.key.split(' ')[0].split('/')[1] + '/' + item.key.split(' ')[0].split('/')[2]
                    monthDay.push(day);
                    monthProcessData.push(item.value);
                    return 0;
                })
                this.setState({
                    monthDay: monthDay,
                    monthProcessData: monthProcessData
                }, () => {
                    this.generatingCharts();
                })
            })
        })
    }

    handleMonthData() {
        let data = this.state.monthDay;
        return data;
    }

    handleMonthProcessData() {
        let data = this.state.monthProcessData;
        return data;
    }

    generatingCharts() {
        if (document.getElementById(this.state.id) != null) {

            let myChart = echarts.init(document.getElementById(this.state.id) as HTMLDivElement);

            myChart.setOption({
                // title: {
                //     text: '堆叠区域图'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['产量'],
                    show: false
                },
                toolbox: {
                    feature: {
                        // saveAsImage: {}
                    }
                },
                grid: {
                    left: this.props.left,
                    right: '4%',
                    top: this.props.top,
                    bottom: this.props.bottom || '6%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.handleMonthData(),
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#183751'
                            }
                        },
                        axisLabel: {
                            color: '#bfbfbf'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#102334',
                                opacity: 0.5
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#183751'
                            }
                        },
                        axisLabel: {
                            color: '#bfbfbf'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#102334',
                                opacity: 0.5
                            }
                        }
                    },
                ],
                dataZoom: [{
                    type: "inside"         //详细配置可见echarts官网
                }], 
                series: [
                    {
                        name: '产量',
                        type: 'line',
                        smooth: true,
                        symbol: 'circle',
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#3680f8' // 0% 处的颜色
                                }, {
                                    offset: 0.7, color: '#102334' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff', //改变折线点的颜色
                                lineStyle: {
                                    color: '#3680f8' //改变折线颜色
                                }
                            }
                        },
                        data: this.handleMonthProcessData()
                    }
                ]
            });
        }
    }

    componentDidMount() {
        this.getData();
        this.timer1 = setInterval(() => {
            this.getData();
        }, 1000 * 60 * 30)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1);
        }
    }

    render() {
        return (
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
        );
    }
}

export default ProductionCharts;