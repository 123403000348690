class CommonMethod {
  /**
   * 处理数据,千加逗号,万加单位
   * @param {*} num 数据
   * @param {*} unit 单位
   */
  static handleData1(num, unit='次') {
    let data = {
      unit: "",
      num: "",
    };
    if (num === 0 || num) {
      data.unit = unit;
      data.num = num;
      if (num > 999 && num < 10000) {
        data.unit = unit;
        data.num =
          Math.floor(num / 1000) +
          "," +
          num.toString().substring(num.toString().length - 3);
      }
      if (num > 9999) {
        data.unit = "万" + unit;
        data.num = (num / 10000).toFixed(1);
      }
    }
    return data;
  }

  /**
   * 处理标签里的今日
   * @param {*} num 代表日期的参数
   */
  static handleData2(num) {
    let data = "今日";
    switch (num) {
      case "0":
        data = "今日";
        break;
      case "1":
        data = "本周";
        break;
      case "2":
        data = "本月";
        break;
      case "3":
      case "4":
      case "5":
        data = "今年";
        break;
      case "6":
        data = "累积";
        break;
      default:
        data = "今日";
    }
    return data;
  }

  /**
   * 修改复制类型图表数据处理
   * @param {*} num 图表数据
   */
  static handleData3(num) {
    let data = {
      dataX: [],
      dataY: [],
    };
    if (num) {
      num.map((item) => {
        data.dataX.push(item.key);
        if (item.value != null) data.dataY.push(item.value);
      });
      return data;
    }
    return data;
  }
  
}

export default CommonMethod;
