import React from 'react';
import HomeFlipper from '../homeFlipper/HomeFlipper';
import Request from '../../api/request';
import API from '../../api/api';
import './HomeEnvironment.scss';

interface Props {
    list: any
}

class HomeEnvironment extends React.Component<Props, any> {
    private getEnvironmentList: Request;
    timer1!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.getEnvironmentList = new Request(API.GET_ENVIRONMENT);
        this.state = {
            environmentList: {},
        }
    }

    /**
     * 处理环境数据
     * @param num - 具体数值
     * @param expand - 是否要扩大或者缩小
     * @param multiple - 扩大缩小的倍数
     */
    handleData1(num: any, expand: any, multiple = 1) {
        let isExpand = expand || false;
        if (isExpand) {
            return (num * multiple).toString() === 'NaN' ? '0' : (num * multiple).toFixed(0).toString();
        } else {
            return Math.round(num).toString() === 'NaN' ? '0' : Math.round(num).toFixed(0).toString();
        }
    }

    render() {
        return (
            <div className="homeEnvironment-container">
                <HomeFlipper
                    list={this.props.list.subtitle1}
                    data={this.handleData1(this.state.environmentList.temperature, false)}
                    // data={Math.round(this.state.environmentList.temperature).toString()==='NaN' ? '0' : Math.round(this.state.environmentList.temperature).toFixed(0).toString()}
                    color={Math.round(this.state.environmentList.temperature) > 30 ? this.props.list.errorColor : this.props.list.normalColor}>

                </HomeFlipper>
                <HomeFlipper
                    list={this.props.list.subtitle2}
                    data={this.handleData1(this.state.environmentList.humidness, true, 100)}
                    // data={(this.state.environmentList.humidness * 100).toString() === 'NaN' ? '0' : (this.state.environmentList.humidness * 100).toFixed(0).toString()}
                    color={(this.state.environmentList.humidness * 100) < 30 || (this.state.environmentList.humidness * 100) > 60 ? this.props.list.errorColor : this.props.list.normalColor}>

                </HomeFlipper>
                <HomeFlipper
                    list={this.props.list.subtitle3}
                    data={this.handleData1(this.state.environmentList.noise, false)}
                    // data={Math.round(this.state.environmentList.noise).toString() === 'NaN' ? '0' : Math.round(this.state.environmentList.noise).toFixed(0).toString()}
                    color={Math.round(this.state.environmentList.noise) > 65 ? this.props.list.errorColor : this.props.list.normalColor}>

                </HomeFlipper>
                <HomeFlipper
                    list={this.props.list.subtitle4}
                    data={this.handleData1(this.state.environmentList.pM25, false)}
                    // data={Math.round(this.state.environmentList.pM25).toString() === 'NaN' ? '0' : Math.round(this.state.environmentList.pM25).toFixed(0).toString()}
                    color={Math.round(this.state.environmentList.pM25) > 70 ? this.props.list.errorColor : this.props.list.normalColor}
                    isOver={Math.round(this.state.environmentList.pM25) > 99 ? true : false}
                >
                </HomeFlipper>
            </div>
        )
    }

    componentDidMount() {
        this.getEnvironmentList.send().then(res => {
            this.setState({
                environmentList: res,
            })
        })

        this.timer1 = setInterval(() => {
            this.getEnvironmentList.send().then(res => {
                this.setState({
                    environmentList: res,
                })
            })
        }, 1000 * 3)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1);
        }
    }
}

export default HomeEnvironment;