import React from 'react';
import './LiveEquipment.scss';
import classNames from 'classnames';

class LiveEquipment extends React.Component<any, any> {
    render() {
        return (
            <React.Fragment>
                <div className="liveEquipment-container">
                    <div className="liveEquipment-title">设备</div>
                    <div className="liveEquipment-content">
                        <div className='liveEquipment-left'>
                            <div className="liveEquipment-word">
                                <div className="liveEquipment-icon lv-url1"></div>
                                {this.props.data.ControllerState || '戒备状态'}
                            </div>
                            <div className="liveEquipment-word">
                                <div className="liveEquipment-icon lv-url2"></div>
                                {this.props.data.OperatingMode || '手动减速'}
                            </div>
                            <div className="liveEquipment-word">
                                <div className={classNames(
                                    {
                                        'liveEquipment-icon': true
                                    },
                                    {
                                        'lv-url3': this.props.data.doTrajectoryRun === '动作中' ? true : false
                                    },
                                    {
                                        'lv-url3-err': this.props.data.doTrajectoryRun === '动作中' ? false : true
                                    }
                                )}></div>
                                动作
                            </div>
                            <div className="liveEquipment-word">
                                <div className={classNames(
                                    {
                                        'liveEquipment-icon': true
                                    },
                                    {
                                        'lv-url4': this.props.data.doHomePos === '到达原点' ? true : false
                                    },
                                    {
                                        'lv-url4-err': this.props.data.doHomePos === '到达原点' ? false : true
                                    }
                                )}></div>
                                到达原点
                            </div>

                        </div>
                        <div className='liveEquipment-right'>
                            <div className="liveEquipment-word">
                                <div className={classNames(
                                    {
                                        'liveEquipment-icon': true
                                    },
                                    {
                                        'lv-url5': this.props.data.doEmergencyStop === '正常' ? true : false
                                    },
                                    {
                                        'lv-url5-err': this.props.data.doEmergencyStop === '正常' ? false : true
                                    }
                                )}></div>
                                急停
                            </div>
                            <div className="liveEquipment-word">
                                <div className={classNames(
                                    {
                                        'liveEquipment-icon': true
                                    },
                                    {
                                        'lv-url6': this.props.data.doTemperatureWarning === '正常' ? true : false
                                    },
                                    {
                                        'lv-url6-err': this.props.data.doTemperatureWarning === '正常' ? false : true
                                    }
                                )}></div>
                                温度
                            </div>
                            <div className="liveEquipment-word">
                                <div className={classNames(
                                    {
                                        'liveEquipment-icon': true
                                    },
                                    {
                                        'lv-url7': this.props.data.doCpuFanNotRunning === '正常' ? true : false
                                    },
                                    {
                                        'lv-url7-err': this.props.data.doCpuFanNotRunning === '正常' ? false : true
                                    }
                                )}></div>
                                风扇
                            </div>
                            <div className="liveEquipment-word">
                                <div className={classNames(
                                    {
                                        'liveEquipment-icon': true
                                    },
                                    {
                                        'lv-url8': this.props.data.doSMBBatteryChargeLow === '正常' ? true : false
                                    },
                                    {
                                        'lv-url8-err': this.props.data.doSMBBatteryChargeLow === '正常' ? false : true
                                    }
                                )}></div>
                                电池
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default LiveEquipment;