import React from 'react';
import './LiveVideo.scss';
import VideoPlayer from './VideoPlayer';

class LiveVideo extends React.Component<any, any> {
    timerVideo!: NodeJS.Timeout;

    constructor(props: any) {
        super(props);
        this.state = {
            key: '0',
        }
    }
    componentDidMount() {
        this.timerVideo = setInterval(() => {
            this.setState({
                key: new Date()
            })
        }, 1000 * 60 * 30);
    }
    componentWillUnmount() {
        if (this.timerVideo != null) {
            clearInterval(this.timerVideo)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="liveVideo-container" key={this.state.key}>
                    <div className="liveVideo-title">视频直播</div>
                    <div className="liveVideo-content">
                        <VideoPlayer src={this.props.data} ></VideoPlayer>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LiveVideo;