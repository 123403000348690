import React from 'react';
import './HomeFlipper.scss';
import classNames from 'classnames';

class HomeFlipper extends React.Component<any, any> {
    render() {
        return (
            <div className={classNames(
                {
                    'homeflipper-container': true
                },
                {
                    'overRun': this.props.isOver
                },
            )}>
                <div className="homeflipper-title">{this.props.list[0]}</div>
                <span className="homeflipper-data" style={{color: this.props.color}}>{this.props.data}</span><span className="homeflipper-unit">{this.props.list[1]}</span>
            </div>
        )
    }
}

export default HomeFlipper;