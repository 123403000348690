import React from 'react';
import './Legend.scss';

class Legend extends React.Component<any, any> {
    render() {
        return (
            <div className="legend-container">
                <div className="legend-content">
                    <div className="legend-icon" style={{ backgroundColor: this.props.list[0].backgroundcolor }}></div>
                    <div className="legend-title">
                        <p>{this.props.list[0].name}</p>
                        <p style={{ color: this.props.list[0].color }}>{this.props.list[0].value}</p>
                    </div>
                </div>
                <div className="legend-content">
                    <div className="legend-icon" style={{ backgroundColor: this.props.list[1].backgroundcolor }}></div>
                    <div className="legend-title">
                        <p>{this.props.list[1].name}</p>
                        <p style={{ color: this.props.list[1].color }}>{this.props.list[1].value}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Legend;