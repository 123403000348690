import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import Request from '../../../api/request';
import API from '../../../api/api';
import Legend from '../legend/Legend';

class EquipmentCharts extends React.Component<any, any> {
    private getEquipmentChartsList: Request;
    timer1!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.getEquipmentChartsList = new Request(API.GET_NEW_MESSAGE);
        this.state = {
            EquipmentChartsList: {},
            dynamicRateTime: 0,
            awaitTime: 0,
        }
    }

    getData() {   
        this.getEquipmentChartsList.send().then(res => {
            // console.log(res)
            this.setState({
                EquipmentChartsList: res,
            }, () => {
                this.setState({
                    dynamicRateTime: this.state.EquipmentChartsList[5].value,
                    awaitTime: this.state.EquipmentChartsList[4].value - this.state.EquipmentChartsList[5].value,
                }, () => {
                    this.generatingCharts()
                    // console.log(this.state.dynamicRateTime)
                })
            })
        })
    }

    handleData() {
        let data =[]
        let overTime = 8 - this.state.dynamicRateTime.toFixed(1) - this.state.awaitTime.toFixed(1);
        data = [
            {
                value: this.state.dynamicRateTime.toFixed(1),
                name: this.state.dynamicRateTime === 0 ? '' : '稼动时长'
            },
            {
                value: this.state.awaitTime.toFixed(1),
                name: this.state.awaitTime === 0 ? '' : '待机时长'
            },
            {
                value: overTime < 0 ? 0 : overTime.toFixed(1),
                name: ''
            }
        ];
        return data;
    }

    /**
     * 处理图例数据
     * @param hour 小时
     * @param minute 分钟
     */
    handleData1(hour:any, minute:any) {
        return ( hour < 1 ? (minute * 60).toFixed(0) + '分钟' : hour + '小时' + (minute * 60).toFixed(0) + '分钟' );
    }

    handleLegend() {
        let data = [];
        let hour1 = Math.floor(this.state.dynamicRateTime.toFixed(1));
        let minute1 = this.state.dynamicRateTime.toFixed(1) - hour1;
        let hour2 = Math.floor(this.state.awaitTime.toFixed(1));
        let minute2 = this.state.awaitTime.toFixed(1) - hour2;
        data = [
            {
                name: '稼动时长',
                // value: hour1 < 1 ? (minute1 * 60).toFixed(0) + '分钟' : hour1 + '小时' + (minute1 * 60).toFixed(0) + '分钟',
                value: this.handleData1(hour1, minute1),
                backgroundcolor: '#3680F8',
                color: '#fff'

            },
            {
                name: '待机时长',
                // value: hour2 < 1 ? (minute2 * 60).toFixed(0) + '分钟' : hour2 + '小时' + (minute2 * 60).toFixed(0) + '分钟',
                value: this.handleData1(hour2, minute2),
                backgroundcolor: '#EFAD1F',
                color: '#EFAD1F'
            }
        ]
        return data;
    }

    generatingCharts() {
        if (document.getElementById('cardMain3-equipmentCharts') != null) {
            let myChart = echarts.init(document.getElementById('cardMain3-equipmentCharts') as HTMLDivElement);

            myChart.setOption({
                title: {
                    // text: '饼图程序调用高亮示例',
                    // left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: `{b} : {c}小时 ({d}%)`
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    right: 'right',
                    data: ['稼动时长', '待机时长'],
                    textStyle: {
                        color: '#fff',
                        fontSize: 16,
                    },
                    padding: [20, 30, 0, 0]
                },
                color: ['#3680f8', '#EFAD1F', '#ccc'],
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: '70%',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        labelLine: {
                            show: false
                        },
                        center: ['30%', '50%'],
                        data: this.handleData(),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        }
    }

    componentDidMount() {
        this.getData();

        this.timer1 = setInterval(() => {
            this.getData();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="cardMain3-equipmentCharts" style={{ width: 400, height: 250 }}></div>
                <Legend list={this.handleLegend()}></Legend>
            </React.Fragment>
        );
    }
}

export default EquipmentCharts;