import React from 'react';
import './HomeCard.scss';
import classNames from 'classnames';

class HomeCard extends React.Component<any, any> {
    handleClick() {
        let toPage = this.props.toPage || '';
        if (this.props.go) {
            this.props.history.push(toPage)
        }
    }

    render() {
        return (
            <div className="homecard-container">
                <div className="wrap1">
                    <div className={classNames(
                        {
                            'title': true
                        },
                        {
                            'pointer': this.props.go
                        }
                    )}
                        onClick={this.handleClick.bind(this)}>
                        <div style={{ backgroundImage: `url(${this.props.list.icon})` }} className="title-image"></div>
                        <span className="title-name">{this.props.list.title}</span>

                        <div className="title-more"></div>

                    </div>
                    {this.props.children}
                </div>
                <div className="wrap2"></div>
                <div className="wrap3"></div>
                <div className="wrap4"></div>
            </div>
        )
    }
}

export default HomeCard;