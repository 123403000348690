import React from 'react';
import './Quality.scss';
import Request from '../../api/request';
import API from '../../api/api';
import moment from 'moment';
import classNames from 'classnames';
import DetailsHeader from '../../components/detailsHeader/DetailsHeader';
import DetailsHeaderTab from '../../components/detailsHeaderTab/DetailsHeaderTab';
import DetailsInfoCard from '../../components/detailsInfoCard/DetailsInfoCard';
import DetailsLineChart from '../../components/charts/detailsLineChart/DetailsLineChart';
import CommonMethod from '../../commonMethod/CommonMethod.js';

class Quality extends React.Component<any, any> {
    private getQualityThroughRateDataList: Request;
    private getQualityDefectDataList: Request;
    private getDefectiveDataList: Request;
    timer1!: NodeJS.Timeout;

    constructor(props: any) {
        super(props);
        this.getQualityThroughRateDataList = new Request(API.GET_QUALITY_THROUGH_RATE_DATA);
        this.getQualityDefectDataList = new Request(API.GET_QUALITY_DEFECT_DATA);
        this.getDefectiveDataList = new Request(API.GET_DEFECTIVE_DATA);
        this.state = {
            choiceNum: '0',
            timeQuantum: {
                beginTime: moment().format('YYYY-MM-DD'),
                endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
                timeRangeType: '0',
            },
            //
            qualityThroughRateDataList: {},
            qualityDefectData: {},
            defectiveDataList: {},
            //
            rateData: 0,
            goodNum: 0,
            defectNum: 0,
            //
            allSendFinished1: 0,
            allSendFinished2: 0,
            allSendFinished3: 0,
        }
    }

    getData() {
        this.getQualityThroughRateDataList.send({
            params: this.state.timeQuantum
        }).then(res => {
            // console.log(res)
            this.setState({
                qualityThroughRateDataList: res
            }, () => {
                this.setState({
                    rateData: this.state.qualityThroughRateDataList.throughRate,
                    goodNum: this.state.qualityThroughRateDataList.goodProductionCount,
                    allSendFinished1: 1,
                })
            })
        })

        this.getQualityDefectDataList.send({
            params: this.state.timeQuantum
        }).then(res => {
            // console.log(res)
            this.setState({
                qualityDefectData: res
            }, () => {
                this.setState({
                    defectNum: this.state.qualityDefectData.defectCount,
                    allSendFinished2: 1,
                })
            })
        })

        this.getDefectiveDataList.send({
            params: {
                startTime: this.state.timeQuantum.beginTime,
                endTime: this.state.timeQuantum.endTime,
            }
        }).then(res => {
            // console.log(res)
            this.setState({
                defectiveDataList: res,
                allSendFinished3: 1,
            })
        })
    }

    onChangeState(data: any) {
        this.setState({
            choiceNum: data.choiceNum,
            timeQuantum: data.timeQuantum,
            allSendFinished1: 0,
            allSendFinished2: 0,
            allSendFinished3: 0,
        }, () => {
            this.getData();
        })
    }

    handleData1() {
        return this.state.rateData ? this.state.rateData : 0;
    }

    getMoment() {
        let beginTime = moment().format('YYYY-MM-DD');
        let endTime = moment().add(1, 'day').format('YYYY-MM-DD');
        switch (this.state.timeQuantum.timeRangeType) {
            case '0':
                beginTime = moment().format('YYYY-MM-DD');
                endTime = moment().add(1, 'day').format('YYYY-MM-DD');
                break;
            case '1':
                beginTime = moment().weekday(1).format('YYYY-MM-DD');
                endTime = moment().weekday(8).format('YYYY-MM-DD');
                break;
            case '2':
                beginTime = moment().startOf("month").format("YYYY-MM-DD");
                endTime = moment().add(1, "month").startOf("month").format("YYYY-MM-DD");
                break;
            case '3':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '4':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '5':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '6':
                beginTime = '2020-01-01';
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            default:
                beginTime = moment().format('YYYY-MM-DD');
                endTime = moment().add(1, 'day').format('YYYY-MM-DD');
        }
        this.setState({
            timeQuantum: {
                beginTime,
                endTime,
                timeRangeType: this.state.timeQuantum.timeRangeType,
            },
        })
    }

    render() {
        let defectiveData = {
            polishedTooSmallCount: 0,
            unit1: '次',
            overPolishedCount: 0,
            unit2: '次',
            coatingMachineSpillGlueCount: 0,
            unit3: '次',
            coatingMachineBreakGlueCount: 0,
            unit4: '次',
            gelatinizeSpillGlueCount: 0,
            unit5: '次',
            gelatinizeBreakGlueCount: 0,
            unit6: '次',
        }
        if (this.state.defectiveDataList) {
            defectiveData.polishedTooSmallCount = this.state.defectiveDataList.polishedTooSmallCount;
            defectiveData.overPolishedCount = this.state.defectiveDataList.overPolishedCount;
            defectiveData.coatingMachineSpillGlueCount = this.state.defectiveDataList.coatingMachineSpillGlueCount;
            defectiveData.coatingMachineBreakGlueCount = this.state.defectiveDataList.coatingMachineBreakGlueCount;
            defectiveData.gelatinizeSpillGlueCount = this.state.defectiveDataList.gelatinizeSpillGlueCount;
            // defectiveData.gelatinizeSpillGlueCount = 10000;
            defectiveData.gelatinizeBreakGlueCount = this.state.defectiveDataList.gelatinizeBreakGlueCount;
        }
        let finished = false;
        if (this.state.allSendFinished1 && this.state.allSendFinished2 && this.state.allSendFinished3) {
            finished = true
        }
        return (
            <div className="quality-container">
                <DetailsHeader>质量</DetailsHeader>
                <DetailsHeaderTab
                    choice={this.state.choiceNum}
                    onClicked={this.onChangeState.bind(this)}
                    isShow={!finished}
                ></DetailsHeaderTab>
                <div className={classNames(
                    {
                        'loading-content': true
                    },
                    {
                        'isShowLoading': finished
                    }
                )}>
                    <div className="loading-container"></div>
                </div>
                <div className={classNames(
                    {
                        'quality-body': true
                    },
                    {
                        'isShowBody': !finished
                    }
                )}>
                    <div className="quality-left">
                        <DetailsInfoCard
                            unit='%'
                            data={this.handleData1()}
                        >直通率</DetailsInfoCard>
                        <DetailsInfoCard
                            unit={CommonMethod.handleData1(this.state.goodNum, '双').unit}
                            data={CommonMethod.handleData1(this.state.goodNum, '双').num}
                        >良品数</DetailsInfoCard>
                        <div className="defectCard">
                            <DetailsInfoCard
                                unit={CommonMethod.handleData1(this.state.defectNum, '次').unit}
                                data={CommonMethod.handleData1(this.state.defectNum, '次').num}
                                isBad={CommonMethod.handleData1(this.state.defectNum, '次').num == '0' ? false : true}
                            >缺陷次数</DetailsInfoCard>
                            <div className="defect-content">
                                <div><p>打磨面积过小</p><span className={classNames(
                                    {
                                        'defect-bad': defectiveData.polishedTooSmallCount > 0
                                    },
                                )}>{CommonMethod.handleData1(defectiveData.polishedTooSmallCount, '次').num}</span>{CommonMethod.handleData1(defectiveData.polishedTooSmallCount, '次').unit}</div>
                                <div><p>打磨面积超限</p><span className={classNames(
                                    {
                                        'defect-bad': defectiveData.overPolishedCount > 0
                                    },
                                )}>{CommonMethod.handleData1(defectiveData.overPolishedCount, '次').num}</span>{CommonMethod.handleData1(defectiveData.overPolishedCount, '次').unit}</div>
                                <div><p>涂处理剂溢胶</p><span className={classNames(
                                    {
                                        'defect-bad': defectiveData.coatingMachineSpillGlueCount > 0
                                    },
                                )}>{CommonMethod.handleData1(defectiveData.coatingMachineSpillGlueCount, '次').num}</span>{CommonMethod.handleData1(defectiveData.coatingMachineSpillGlueCount, '次').unit}</div>
                                <div><p>涂处理剂断胶</p><span className={classNames(
                                    {
                                        'defect-bad': defectiveData.coatingMachineBreakGlueCount > 0
                                    },
                                )}>{CommonMethod.handleData1(defectiveData.coatingMachineBreakGlueCount, '次').num}</span>{CommonMethod.handleData1(defectiveData.coatingMachineBreakGlueCount, '次').unit}</div>
                                <div><p>涂胶溢胶</p><span className={classNames(
                                    {
                                        'defect-bad': defectiveData.gelatinizeSpillGlueCount > 0
                                    },
                                )}>{CommonMethod.handleData1(defectiveData.gelatinizeSpillGlueCount, '次').num}</span>{CommonMethod.handleData1(defectiveData.gelatinizeSpillGlueCount, '次').unit}</div>
                                <div><p>涂胶断胶</p><span className={classNames(
                                    {
                                        'defect-bad': defectiveData.gelatinizeBreakGlueCount > 0
                                    },
                                )}>{CommonMethod.handleData1(defectiveData.gelatinizeBreakGlueCount, '次').num}</span>{CommonMethod.handleData1(defectiveData.gelatinizeBreakGlueCount, '次').unit}</div>
                            </div>
                        </div>
                    </div>
                    <div className="quality-right">
                        <DetailsLineChart
                            theid='qua-rateData'
                            dataY={CommonMethod.handleData3(this.state.qualityThroughRateDataList.chartModels).dataY}
                            dataX={CommonMethod.handleData3(this.state.qualityThroughRateDataList.chartModels).dataX}
                            size={{ width: 1200, height: 371 }}
                        >直通率(%)</DetailsLineChart>
                        <DetailsLineChart
                            theid='qua-defectNum'
                            dataY={CommonMethod.handleData3(this.state.qualityDefectData.chartModels).dataY}
                            dataX={CommonMethod.handleData3(this.state.qualityDefectData.chartModels).dataX}
                            lineColor={'#FC9800'}
                            defect={true}
                            areaStyle1={'rgba(252, 151, 0, 0.775)'}
                            areaStyle2={'rgba(252, 151, 0, 0.1)'}
                            size={{ width: 1200, height: 371 }}
                        >缺陷(次)</DetailsLineChart>
                    </div>
                </div>

            </div>
        )
    }

    componentDidMount() {
        this.getData();
        this.timer1 = setInterval(() => {
            this.getData();
            this.getMoment();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1)
        }
    }
}

export default Quality;