import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../pages/home/Home';
import Environment from '../pages/environment/Environment';
import Quality from '../pages/quality/Quality';
import Production from '../pages/production/Production';
import Equipment from '../pages/equipment/Equipment';
import Live from '../pages/live/Live';
import ProductionLineLive from '../pages/live/ProductionLineLive'
import VisionLive from '../pages/live/VisionLive'


class Routers extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path='/live/:id' component={Live}>
                    </Route>
                    <Route exact path='/equipment' component={Equipment}>
                    </Route>
                    <Route exact path='/production' component={Production}>
                    </Route>
                    <Route exact path='/quality' component={Quality}>
                    </Route>
                    <Route exact path='/environment' component={Environment}>
                    </Route>
                    <Route exact path='/home' component={Home}>
                    </Route>
                    <Route exact path='/' component={Home}>
                    </Route>
                    <Route exact path='/productionLineLive' component={ProductionLineLive}>
                    </Route>
                    <Route exact path='/visionLive' component={VisionLive}>
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default Routers