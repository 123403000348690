import React from 'react';
import './DetailsInfoCard.scss';
import classNames from 'classnames';

class DetailsInfoCard extends React.Component<any, any> {
    render() {
        return (
            <div className="detailsInfoCard-container">
                <div className="detailsInfoCard-title">{this.props.children}</div>
                <div className={classNames(
                    {
                        'detailsInfoCard-content': true
                    },
                    {
                        'overRun': this.props.isOver
                    },
                )}>
                    <span className={classNames(
                        {
                            'detailsInfoCard-span': true
                        },
                        {
                            'worring': this.props.isWorring
                        },
                        {
                            'bad': this.props.isBad
                        },
                    )}>{this.props.data}</span>{this.props.unit}
                </div>
            </div>
        )
    }
}

export default DetailsInfoCard;