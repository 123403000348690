import React from 'react';
import './Environment.scss';
import Request from '../../api/request';
import API from '../../api/api';
import DetailsHeader from '../../components/detailsHeader/DetailsHeader';
import DetailsLineChart from '../../components/charts/detailsLineChart/DetailsLineChart';
import DetailsInfoCard from '../../components/detailsInfoCard/DetailsInfoCard';
import CommonMethod from '../../commonMethod/CommonMethod.js';
import classnames from 'classnames'

class Environment extends React.Component<any, any> {
    private getHistoryEnvironmentDataList: Request;
    private getEnvironmentList: Request;
    timer1!: NodeJS.Timeout;

    constructor(props: any) {
        super(props);
        this.getHistoryEnvironmentDataList = new Request(API.GET_HISTORY_ENVIRONMENT_DATA + '0');
        this.getEnvironmentList = new Request(API.GET_ENVIRONMENT_DATA);
        this.state = {
            historyEnvironmentDataList: [],
            timeList: [],
            temperatureList: [],
            noiseList: [],
            humidnessList: [],
            pM25List: [],
            environmentList: [{
                temperature: 0,
                humidness: 0,
                noise: 0,
                pM25: 0
            }]
        }
    }

    getData() {
        this.getHistoryEnvironmentDataList.send().then(res => {
            this.setState({
                historyEnvironmentDataList: res
            }, () => {
                let time: any[] = [];
                let tem: any[] = [];
                let noise: any[] = [];
                let humidness: any[] = [];
                let pm25: any[] = [];
                this.state.historyEnvironmentDataList.map((item: any) => {
                    if (item.value != null) {
                        time.push(item.key);
                        tem.push(item.value.temperature);
                        noise.push(item.value.noise);
                        humidness.push((item.value.humidness * 100).toFixed(0));
                        pm25.push(item.value.pM25)
                    }
                })
                this.setState({
                    temperatureList: tem,
                    timeList: time,
                    noiseList: noise,
                    humidnessList: humidness,
                    pM25List: pm25
                })
            })
        })

        this.getEnvironmentList.send().then(res => {
            this.setState({
                environmentList: res
            })
        })
    }

    /**
     * 处理详情环境数据
     * @param num - 具体数值
     * @param expand - 是否要扩大或者缩小
     * @param multiple - 扩大缩小的倍数
     */
    handleData1(num: any, expand: any, multiple = 1) {
        let isExpand = expand || false;
        if (isExpand) {
            return (isNaN(num * multiple) ? '0' : (num * multiple).toFixed(0).toString());
        } else {
            return (isNaN(parseInt(num)) ? '0' : parseInt(num).toString());
        }
    }

    render() {
        return (
            <div className="environment-container">
                <DetailsHeader>环境</DetailsHeader>
                <div  className={classnames({
                    'environment-body':true,
                    'ipad-environment-body':(window as any).isIpad
                })}>
                    <div className="environment-left">
                        <DetailsLineChart
                            theid='env-temperature'
                            boundaryGap={["0", "6"]}
                            markLineData={[
                                {
                                    yAxis: 30,
                                    name: 'top'
                                },
                                {
                                    yAxis: 15,
                                    name: 'bottom'
                                },
                            ]}
                            dataY={this.state.temperatureList}
                            dataX={this.state.timeList}
                            maxData='40'
                            size={{ width: 700, height: 371 }}
                        >温度(℃)</DetailsLineChart>
                        <DetailsLineChart
                            theid='env-noise'
                            boundaryGap={["0", "2"]}
                            markLineData={[
                                {
                                    yAxis: 65,
                                    name: 'bottom'
                                },
                            ]}
                            dataY={this.state.noiseList}
                            dataX={this.state.timeList}
                            maxData='100'
                            size={{ width: 700, height: 371 }}
                        >噪音(dB)</DetailsLineChart>
                    </div>
                    <div className="environment-middle">
                        <DetailsInfoCard
                            unit="℃"
                            data={this.handleData1(this.state.environmentList.temperature, false)}
                            // data={isNaN(parseInt(this.state.environmentList.temperature)) ? '0' : parseInt(this.state.environmentList.temperature).toString()}
                            isWorring={parseInt(this.state.environmentList.temperature) > 30 ? true : false}
                        >温度</DetailsInfoCard>
                        <DetailsInfoCard
                            unit="%"
                            data={this.handleData1(this.state.environmentList.humidness, true, 100)}
                            // data={isNaN(this.state.environmentList.humidness * 100) ? '0' : (this.state.environmentList.humidness * 100).toFixed(0).toString()}
                            isWorring={(this.state.environmentList.humidness < 0.3 || this.state.environmentList.humidness > 0.6) ? true : false}
                        >湿度</DetailsInfoCard>
                        <DetailsInfoCard
                            unit="dB"
                            data={this.handleData1(this.state.environmentList.noise, false)}
                            // data={isNaN(parseInt(this.state.environmentList.noise)) ? '0' : parseInt(this.state.environmentList.noise).toString()}
                            isWorring={parseInt(this.state.environmentList.noise) > 65 ? true : false}
                        >噪音</DetailsInfoCard>
                        <DetailsInfoCard
                            unit="μg/m³"
                            data={this.handleData1(this.state.environmentList.pM25, false)}
                            // data={isNaN(parseInt(this.state.environmentList.pM25)) ? '0' : parseInt(this.state.environmentList.pM25).toString()}
                            isWorring={parseInt(this.state.environmentList.pM25) > 70 ? true : false}
                            isOver={parseInt(this.state.environmentList.pM25) > 99 ? true : false}
                        >PM2.5</DetailsInfoCard>
                    </div>
                    <div className="environment-right">
                        <DetailsLineChart
                            theid='env-humidness'
                            boundaryGap={["0", "2"]}
                            markLineData={[
                                {
                                    yAxis: 60,
                                    name: 'top'
                                },
                                {
                                    yAxis: 30,
                                    name: 'bottom'
                                },
                            ]}
                            dataY={this.state.humidnessList}
                            dataX={this.state.timeList}
                            maxData='100'
                            size={{ width: 700, height: 371 }}
                        >湿度(%)</DetailsLineChart>
                        <DetailsLineChart
                            theid='env-pM25'
                            boundaryGap={["0", "1"]}
                            markLineData={[
                                {
                                    yAxis: 70,
                                    name: 'bottom'
                                },
                            ]}
                            dataY={this.state.pM25List}
                            dataX={this.state.timeList}
                            maxData='100'
                            size={{ width: 700, height: 371 }}
                        >PM2.5(μg/m³)</DetailsLineChart>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getData();
        this.timer1 = setInterval(() => {
            this.getData();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1)
        }
    }
}

export default Environment;