import React, { Component } from "react";
import Videojs from "video.js";
//import "videojs-contrib-hls";
import "video.js/dist/video-js.css";

class VideoPlayer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentWillUnmount() {
    // 销毁播放器
    if (this.player) {
      this.player.dispose();
    }
  }
  componentDidMount() {
    const { height, width, src } = this.props;
    this.player = Videojs(
      "player",
      {
        height,
        width,
        bigPlayButton: true,
        textTrackDisplay: false,
        errorDisplay: false,
        controlBar: true,
        type: "application/x-mpegURL",
      },
      function () {
        this.play();
      }
    );
    this.player.src({ src });
  }

  render() {
    return (
      <video
        id="player"
        className="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
        preload="auto"
        poster=""
        autoPlay
        controls={true}
        muted={true}
      ></video>
    );
  }
}

export default VideoPlayer;
