import React from 'react';
import Request from '../../api/request';
import API from '../../api/api';
import './HomeFooter.scss';
import HomeQR from '../homeQR/HomeQR';
import HomeCard from '../homeCard/HomeCard';
import StateCard from '../stateCard/StateCard';
import classNames from 'classnames';
import { Link } from 'react-router-dom';


class HomeFooter extends React.Component<any, any> {
    private getCondition1: Request;
    private getCondition2: Request;
    private getCondition3: Request;
    private getCondition4: Request;
    private getCondition5: Request;
    timer1!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.getCondition1 = new Request(API.GET_ROBOT_DATA1);
        this.getCondition2 = new Request(API.GET_ROBOT_DATA2);
        this.getCondition3 = new Request(API.GET_ROBOT_DATA3);
        this.getCondition4 = new Request(API.GET_ROBOT_DATA4);
        this.getCondition5 = new Request(API.GET_ROBOT_DATA5);
        this.state = {
            qrCodeList: [
                {
                    title: 'ZS-TEK.com',
                    icon: require('../../assets/images/gw.svg')
                },
                {
                    title: '手机看板',
                    icon: require('../../assets/images/A2-mobile.svg')
                }
            ],
            homeCard:
            {
                title: '加工实况',
                icon: require('../../assets/images/shikuang.png'),
            }
            ,
            stateCardList: [
                {
                    title: '涂胶工位2',
                    status: true,
                    toPage: '/live/4'

                },
                {
                    title: '涂胶工位1',
                    status: true,
                    toPage: '/live/3'
                },
                {
                    title: '处理剂工位2',
                    status: true,
                    toPage: '/live/2'
                },
                {
                    title: '处理剂工位1',
                    status: true,
                    toPage: '/live/1'
                },
                {
                    title: '打磨工位1',
                    status: true,
                    toPage: '/live/0'
                },
            ],
        }
    }

    render() {
        return (
            <div className={classNames({
                'homefooter-container': true,
                'ipad': (window as any).isIpad
            })}>
                <div className="messaga-box">
                    <HomeQR list={this.state.qrCodeList}></HomeQR>
                    <HomeCard list={this.state.homeCard}>
                        <div className="statecard-wrap">

                            <div className='live-btn'>
                                <Link to={{ pathname: '/productionLineLive' }}>
                                    <div className='live-btn-content' >
                                        <span className='live-btn-icon'></span>
                                        <span>产线</span>
                                    </div>
                                </Link>
                            </div>

                            <div className='live-btn'>
                                <Link to={{ pathname: '/visionLive' }}>
                                    <div className='live-btn-content' >
                                        <span className='live-btn-icon'></span>
                                        <span>视觉检测</span>
                                    </div>
                                </Link>
                            </div>

                            {
                                this.state.stateCardList.map((item: any, idx: number) => {
                                    return <StateCard title={item.title} status={item.status} toPage={item.toPage} key={idx}></StateCard>
                                })
                            }
                        </div>
                        <div className="statecard-picture"></div>
                    </HomeCard>
                </div>
            </div>
        )
    }

    getRobotStatus() {
        let stateCardList: Array<any> = [...this.state.stateCardList]
        Promise.all([this.getCondition1.send(),
        this.getCondition2.send(),
        this.getCondition3.send(),
        this.getCondition4.send(),
        this.getCondition5.send()])
            .then(res => {
                res.forEach((item: any, idx: number) => {
                    if (item.doCpuFanNotRunning === '正常' &&
                        item.doEmergencyStop === '正常' &&
                        item.doSMBBatteryChargeLow === '正常' &&
                        item.doTemperatureWarning === '正常') {
                        stateCardList[idx].status = true
                    } else {
                        stateCardList[idx].status = false
                    }
                })
                this.setState({ stateCardList })
            })
    }
    componentDidMount() {
        this.getRobotStatus()
        this.timer1 = setInterval(() => {
            this.getRobotStatus()
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1);
        }
    }
}

export default HomeFooter;