import React from 'react';
import echarts from 'echarts';
import './DetailsLineChart.scss';
import classNames from 'classnames';

class DetailsLineChart extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }
    
    generatingCharts() {
        
        if (document.getElementById(this.props.theid) != null) {

            let myChart = echarts.init(document.getElementById(this.props.theid) as HTMLDivElement);

            myChart.setOption({
                // title: {
                //     text: '堆叠区域图'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['产量'],
                    show: false
                },
                toolbox: {
                    feature: {
                        // saveAsImage: {}
                    }
                },
                grid: {
                    left: '0%',
                    right: this.props.gridRight || '4%',
                    top: '10%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.props.dataX,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#233b51'
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: this.props.fontsize || 20
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#233b51',
                                opacity: 0.5
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        boundaryGap: this.props.boundaryGap,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#233b51'
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: this.props.fontsize || 20
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#233b51',
                                opacity: 0.5
                            }
                        },
                        max: this.props.maxData || undefined,
                    },
                ],
                dataZoom: [{
                    type: "inside"         //详细配置可见echarts官网
                }],
                series: [
                    {
                        name: this.props.seriesName || '',
                        type: 'line',
                        smooth: true,
                        symbol: 'circle',
                        markLine: {
                            silent: true,
                            symbol: 'none',
                            label: {
                                show: false
                            },
                            lineStyle: {
                                type: 'dashed',
                                color: '#FC9800',
                                width: 2
                            },
                            data: this.props.markLineData
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.props.areaStyle1 || '#2b5b82' // 0% 处的颜色
                                }, {
                                    offset: 0.7, color: this.props.areaStyle2 || '#102334' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff', //改变折线点的颜色
                                lineStyle: {
                                    color: this.props.lineColor || '#3680f8' //改变折线颜色
                                }
                            }
                        },
                        data: this.props.dataY
                    }
                ]
            });
        }
    }

    render() {
        this.generatingCharts();
        return (
            <div className="detailsLineChart-container">
                <div className="detailsLineChart-header">
                    <div className={classNames(
                        {
                            'detailsLineChart-icon': true
                        },
                        {
                            'detailsLineChart-icon2': this.props.defect
                        }
                    )}></div>
                    <div className="detailsLineChart-title">{this.props.children}</div>
                </div>
                <div id={this.props.theid} style={{ width: this.props.size.width, height: this.props.size.height }}></div>
            </div>
        )
    }
}

export default DetailsLineChart;