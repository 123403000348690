import service from "./service"
interface Config {
  params?:Object
  data?:Object
  options?:Array<any> | string
}

class Request {
  private api: string
  private method: string
  constructor(api: string, method?: string) {
    this.api = api
    this.method = method || 'get'

  }
  /**
   * 
   * @param options 
   */
  public send(config?:Config) {
    let  requestOptions:any = {method:this.method || 'get' }

    if(config && config.options) {
      if(Array.isArray(config.options)) {
        requestOptions.url= this.api + config.options.join('/')
      }else {
        requestOptions.url = this.api+config.options
      }
      
    }else {
      requestOptions.url = this.api
    }

    if(config && config.params) {
      requestOptions.params = config.params
    }
    if(config && config.data) {
      requestOptions.data = config.data
    }

    return service(requestOptions) 
  }
}
export default Request
