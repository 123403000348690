import React from 'react';
import './LiveQuality.scss';
import classNames from 'classnames';
import DetailsInfoCard from '../../components/detailsInfoCard/DetailsInfoCard';
import DetailsLineChart from '../../components/charts/detailsLineChart/DetailsLineChart';
import moment from 'moment';

class LiveQuality extends React.Component<any, any> {
    render() {
        let yearName = '今年(按周)';
        if (this.props.choice === '3') {
            yearName = '今年(按周)';
        } else if (this.props.choice === '4') {
            yearName = '今年(按月)';
        } else if (this.props.choice === '5') {
            yearName = '今年(按季度)';
        } else if (this.props.choice === '6') {
            yearName = '累积';
        }

        let badType = ['涂胶溢胶', '涂胶断胶'];
        let badNum = [0, 0]
        if (this.props.data) {
            if (this.props.pageId === '0') {
                badType = ['打磨面积过小', '打磨面积超限'];
                badNum = [this.props.data.polishedTooSmallCount, this.props.data.overPolishedCount];
            } else if (this.props.pageId === '1' || this.props.pageId === '2') {
                badType = ['涂处理溢胶', '涂处理断胶'];
                badNum = [this.props.data.coatingMachineSpillGlueCount, this.props.data.coatingMachineBreakGlueCount];
            } else if (this.props.pageId === '3' || this.props.pageId === '4') {
                badNum = [this.props.data.gelatinizeSpillGlueCount, this.props.data.gelatinizeBreakGlueCount];
            }
        }

        return (
            <React.Fragment>
                <div className="liveQuality-container">
                    <div className="liveQuality-title">质量</div>
                    <div className="liveQuality-content">
                        {/* 选项卡开始 */}
                        <div>
                            <div className={classNames(
                                {
                                    'liveQuality-detailsHeaderTab-container': true
                                },
                                {
                                    'isShow': this.props.isShow
                                }
                            )}>
                                <div className="liveQuality-detailsHeaderTab-content">
                                    <span
                                        className={classNames(
                                            {
                                                'choice': this.props.choice === '0'
                                            },
                                        )}
                                        onClick={() => this.props.onClicked({
                                            choiceNum: '0',
                                            timeQuantum: {
                                                beginTime: moment().format('YYYY-MM-DD'),
                                                endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
                                                timeRangeType: '0',
                                            }
                                        })}
                                    >今日</span>
                                    <span
                                        className={classNames(
                                            {
                                                'choice': this.props.choice === '1'
                                            },
                                        )}
                                        onClick={() => this.props.onClicked({
                                            choiceNum: '1',
                                            timeQuantum: {
                                                beginTime: moment().weekday(1).format('YYYY-MM-DD'),
                                                endTime: moment().weekday(8).format('YYYY-MM-DD'),
                                                timeRangeType: '1',
                                            }
                                        })}
                                    >本周</span>
                                    <span
                                        className={classNames(
                                            {
                                                'choice': this.props.choice === '2'
                                            },
                                        )}
                                        onClick={() => this.props.onClicked({
                                            choiceNum: '2',
                                            timeQuantum: {
                                                beginTime: moment().startOf("month").format("YYYY-MM-DD"),
                                                endTime: moment().add(1, "month").startOf("month").format("YYYY-MM-DD"),
                                                timeRangeType: '2',
                                            }
                                        })}
                                    >本月</span>
                                    <span
                                        className={classNames(
                                            {
                                                'liveQuality-year': true
                                            },
                                            {
                                                'choice': (this.props.choice === '3' || this.props.choice === '4' || this.props.choice === '5' || this.props.choice === '6')
                                            },
                                        )}
                                    >{yearName}<div
                                        className={classNames(
                                            {
                                                'liveQuality-more': true
                                            },
                                            {
                                                'liveQuality-more2': this.props.choice === '5'
                                            },
                                            {
                                                'liveQuality-more3': this.props.choice === '6'
                                            },
                                        )}></div>
                                        <div className="liveQuality-yearList">
                                            <span
                                                onClick={() => this.props.onClicked({
                                                    choiceNum: '3',
                                                    timeQuantum: {
                                                        beginTime: moment().startOf("year").format("YYYY-MM-DD"),
                                                        endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                                        timeRangeType: '3',
                                                    }
                                                })}
                                            >今年(按周)</span>
                                            <span
                                                onClick={() => this.props.onClicked({
                                                    choiceNum: '4',
                                                    timeQuantum: {
                                                        beginTime: moment().startOf("year").format("YYYY-MM-DD"),
                                                        endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                                        timeRangeType: '4',
                                                    }
                                                })}
                                            >今年(按月)</span>
                                            <span
                                                onClick={() => this.props.onClicked({
                                                    choiceNum: '5',
                                                    timeQuantum: {
                                                        beginTime: moment().startOf("year").format("YYYY-MM-DD"),
                                                        endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                                        timeRangeType: '5',
                                                    }
                                                })}
                                            >今年(按季度)</span>
                                            <span
                                                onClick={() => this.props.onClicked({
                                                    choiceNum: '6',
                                                    timeQuantum: {
                                                        beginTime: '2020-01-01',
                                                        endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                                        timeRangeType: '6',
                                                    }
                                                })}
                                            >累积</span>
                                        </div>
                                    </span>
                                </div>
                                <div className="liveQuality-detailsHeaderTab-export">
                                    <div className="liveQuality-detailsHeaderTab-icon"></div>
                                    <div className="liveQuality-detailsHeaderTab-title">导出</div>
                                </div>
                            </div>
                        </div>
                        {/* 选项卡结束 */}
                        <div className={classNames(
                            {
                                'loading-content': true
                            },
                            {
                                'isShowLoading': !this.props.isShow
                            }
                        )}>
                            <div className="loading-container"></div>
                        </div>
                        <div className={classNames(
                            {
                                'liveQuality-show-body': true
                            },
                            {
                                'isShowBody': this.props.isShow
                            }
                        )}>
                            <div className="liveQuality-cardTop">
                                <DetailsInfoCard
                                    unit='%'
                                    data={this.props.data.throughRate}
                                >直通率</DetailsInfoCard>
                                <DetailsInfoCard
                                    unit={this.props.data.unit0}
                                    data={this.props.data.goodProductionCount}
                                >加工成功</DetailsInfoCard>
                            </div>
                            <div className="liveQuality-cardBottom">
                                <DetailsInfoCard
                                    unit='次'
                                    isBad={badNum[0] != 0}
                                    data={badNum[0]}
                                >{badType[0]}</DetailsInfoCard>
                                <DetailsInfoCard
                                    unit='次'
                                    isBad={badNum[1] != 0}
                                    data={badNum[1]}
                                >{badType[1]}</DetailsInfoCard>
                            </div>
                            <div className="liveQuality-charts">
                                <DetailsLineChart
                                    theid='live-rateData'
                                    dataY={this.props.chartsData.dataY}
                                    dataX={this.props.chartsData.dataX}
                                    gridRight='6%'
                                    size={{ width: 600, height: 380 }}
                                >直通率(%)</DetailsLineChart>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LiveQuality;