import React from 'react';
import './DetailsHeader.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
//
class DetailsHeader extends React.Component {
    render() {
        return (
            <div className={classnames({
                'detailsHeader-container': true,
                'ipad-head': (window as any).isIpad
            })}>
                <Link to="/home"><div className="detailsHeader-icon"></div></Link>
                <div className="detailsHeader-title">{this.props.children}</div>
                <div className={classnames(
                    {
                        'detailsHeader-ipadenv': this.props.children === '环境' ? (window as any).isIpad ? true : false : false,
                    },
                    {
                        'detailsHeader-noenv': this.props.children === '环境' ? false : true,
                    },
                    {
                        'detailsHeader-env': this.props.children === '环境' ? true : false,
                    }
                )}>
                    <div className="env-icon"></div>
                    <div className="env-title">导出</div>
                </div>
            </div>
        )
    }
}

export default DetailsHeader;