import React from 'react';
import Request from '../../api/request';
import API from '../../api/api';
import './HomeBody.scss';
import HomeCard from '../homeCard/HomeCard';
import HomeFlipper from '../homeFlipper/HomeFlipper';
import HomeEnvironment from '../homeEnvironment/HomeEnvironment';
import EquipmentCharts from '../charts/equipmentCharts/EquipmentCharts';
import QualityCharts from '../charts/qualityCharts/QualityCharts';
import ProductionCharts from '../charts/productionCharts/ProductionCharts';

class HomeBody extends React.Component<any, any> {
    private getGoodProcessCount: Request;
    private getProcessCount: Request;
    private getThroughRateAndDynamicRate: Request;
    private getDefectCount: Request;
    private getEnergySumList: Request;
    timer1!: NodeJS.Timeout;
    timer2!: NodeJS.Timeout;
    timer3!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.getGoodProcessCount = new Request(API.GET_TODAY_PROCESS_DATA);
        this.getProcessCount = new Request(API.GET_All_PROCESS_DATA);
        this.getThroughRateAndDynamicRate = new Request(API.GET_NEW_MESSAGE);
        this.getDefectCount = new Request(API.GET_QUALITY_DEFECT_DATA);
        this.getEnergySumList = new Request(API.GET_TO_DAY_ENERGY_DATA);
        this.state = {
            goodProcessCountList: { 'goodProcessCount': 0 },
            processCountList: {},
            throughRate: 0,
            defectCountList: { 'defectCount': 0 },
            dynamicRate: 0,
            energySumList: { 'energySum': 0 },
            throughRateAndDynamicRate: {},
            homeCardList: [
                {
                    title: '生产',
                    icon: require('../../assets/images/shengchan.png'),
                    subtitle1: ['今日产量', '双'],
                    subtitle2: ['累计产量', '万双'],
                    color: '#05D380'
                },
                {
                    title: '质量',
                    icon: require('../../assets/images/zhiliang.png'),
                    subtitle1: ['直通率', '%'],
                    subtitle2: ['质量异常', '次'],
                    color1: '#05D380',
                    color2: '#EFAD1F'
                },
                {
                    title: '设备',
                    icon: require('../../assets/images/shebei.png'),
                    subtitle1: ['稼动率', '%'],
                    subtitle2: ['能耗', '度'],
                    color1: '#05D380',
                    color2: '#EFAD1F'
                },
                {
                    title: '环境',
                    icon: require('../../assets/images/huanjing.png'),
                    subtitle1: ['温度', '℃'],
                    subtitle2: ['湿度', '%'],
                    subtitle3: ['噪音', 'dB'],
                    subtitle4: ['PM2.5', 'μg/m3'],
                    normalColor: '#0FB964',
                    errorColor: '#EF1F1F'
                }
            ]
        }
    }

    handleProcessCount() {
        return (Math.floor(this.state.processCountList.processCount / 1000) / 10).toString() === 'NaN' ? '0' : (Math.floor(this.state.processCountList.processCount / 1000) / 10).toString()
    }

    render() {
        let cardMain1 =
            <React.Fragment>
                <div className="cardMain-container">
                    <HomeFlipper
                        list={this.state.homeCardList[0].subtitle2}
                        data={this.handleProcessCount()}
                        color={this.state.homeCardList[0].color}>

                    </HomeFlipper>
                    <HomeFlipper
                        list={this.state.homeCardList[0].subtitle1}
                        data={this.state.goodProcessCountList.goodProcessCount}
                        color={this.state.homeCardList[0].color}>

                    </HomeFlipper>
                </div>
                <div>
                    <div className="cardMain-title">
                        <div className="cardMain-icon"></div><span className="cardMain-word">近15日产量(双)</span>
                    </div>
                    <ProductionCharts
                        width={400}
                        height={250}
                        top={'7%'}
                        left={'8%'}
                        bottom={'8%'}
                        id={"cardMain1-productioncharts"}
                    >
                    </ProductionCharts>
                </div>
            </React.Fragment>
        let cardMain2 =
            <React.Fragment>
                <div className="cardMain-container">
                    <HomeFlipper
                        list={this.state.homeCardList[1].subtitle1}
                        data={this.state.throughRate}
                        color={this.state.homeCardList[1].color1}>

                    </HomeFlipper>
                    <HomeFlipper
                        list={this.state.homeCardList[1].subtitle2}
                        data={this.state.defectCountList.defectCount}
                        color={this.state.defectCountList.defectCount === 0 ? this.state.homeCardList[1].color1 : this.state.homeCardList[1].color2}>

                    </HomeFlipper>
                </div>
                <div>
                    <div className="cardMain-title">
                        <div className="cardMain-icon"></div><span className="cardMain-word">质量异常分析</span>
                    </div>
                    <QualityCharts></QualityCharts>
                </div>
            </React.Fragment>
        let cardMain3 =
            <React.Fragment>
                <div className="cardMain-container">

                    <HomeFlipper
                        list={this.state.homeCardList[2].subtitle1}
                        data={this.state.dynamicRate === 100 ? '100' : this.state.dynamicRate.toFixed(1)}
                        color={this.state.homeCardList[2].color1}>

                    </HomeFlipper>
                    <HomeFlipper
                        list={this.state.homeCardList[2].subtitle2}
                        data={this.state.energySumList.energySum}
                        color={this.state.energySumList.energySum === 0 ? this.state.homeCardList[2].color1 : this.state.homeCardList[2].color2}>

                    </HomeFlipper>
                </div>
                <div>
                    <div className="cardMain-title">
                        <div className="cardMain-icon"></div><span className="cardMain-word">稼动时长</span>
                    </div>
                    <EquipmentCharts></EquipmentCharts>
                </div>
            </React.Fragment>
        let cardMain4 =
            <div className="cardMain4-container">
                <HomeEnvironment
                    list={this.state.homeCardList[3]}>

                </HomeEnvironment>
            </div>
        return (
            <React.Fragment>
                <div className="homebody-container">
                    <HomeCard list={this.state.homeCardList[0]} history={this.props.history} toPage="/production" go={true}>{cardMain1}</HomeCard>
                    <HomeCard list={this.state.homeCardList[1]} history={this.props.history} toPage="/quality" go={true}>{cardMain2}</HomeCard>
                    <HomeCard list={this.state.homeCardList[2]} history={this.props.history} toPage="/equipment" go={true}>{cardMain3}</HomeCard>
                    <HomeCard list={this.state.homeCardList[3]} history={this.props.history} toPage="/environment" go={true}>{cardMain4}</HomeCard>
                </div>
            </React.Fragment>
        )
    }

    getDataGoodProcessCount() {
        this.getGoodProcessCount.send().then(res => {
            this.setState({
                goodProcessCountList: res,
            })
        })
    }

    getDataProcessCount() {
        this.getProcessCount.send().then(res => {
            this.setState({
                processCountList: res,
            })
        })
    }

    getData2() {
        this.getThroughRateAndDynamicRate.send().then(res => {
            this.setState({
                throughRateAndDynamicRate: res,
            },
                () => {
                    this.setState({
                        throughRate: this.state.throughRateAndDynamicRate[3].value,
                        dynamicRate: this.state.throughRateAndDynamicRate[6].value
                    })
                })
        })

        this.getDefectCount.send().then(res => {
            this.setState({
                defectCountList: res,
            })
        })

        this.getEnergySumList.send().then(res => {
            // console.log(res);
            this.setState({
                energySumList: res,
            })
        })
    }

    componentDidMount() {
        this.getDataGoodProcessCount();
        this.getDataProcessCount()
        this.getData2();

        this.timer1 = setInterval(() => {
            this.getDataGoodProcessCount();
        }, 1000 * 3)

        this.timer2 = setInterval(() => {
            this.getDataProcessCount();
        }, 1000 * 3)

        this.timer3 = setInterval(() => {
            this.getData2();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1)
        }
        if (this.timer2 != null) {
            clearInterval(this.timer2)
        }
        if (this.timer3 != null) {
            clearInterval(this.timer3)
        }
    }
}

export default HomeBody;