import React from 'react';
import './Equipment.scss';
import Request from '../../api/request';
import API from '../../api/api';
import moment from 'moment';
import classNames from 'classnames';
import DetailsHeader from '../../components/detailsHeader/DetailsHeader';
import DetailsHeaderTab from '../../components/detailsHeaderTab/DetailsHeaderTab';
import DetailsInfoCard from '../../components/detailsInfoCard/DetailsInfoCard';
import DetailsHeapBarChart from '../../components/charts/detailsHeapBarChart/DetailsHeapBarChart';
import DetailsLineChart from '../../components/charts/detailsLineChart/DetailsLineChart';
import CommonMethod from '../../commonMethod/CommonMethod.js';

class Equipment extends React.Component<any, any> {
    private getEquipmentUtilizationDataList: Request;
    private getEquipmentSystemStateDataList: Request;
    private getEquipmentEnergyData: Request;
    timer1!: NodeJS.Timeout;

    constructor(props: any) {
        super(props);
        this.getEquipmentUtilizationDataList = new Request(API.GET_EQUIPMENT_UTILIZATION_DATA);
        this.getEquipmentSystemStateDataList = new Request(API.GET_EQUIPMENT_SYSTEM_STATE_DATE);
        this.getEquipmentEnergyData = new Request(API.GET_EQUIPMENT_ENERGY_DATA);
        this.state = {
            choiceNum: '0',
            timeQuantum: {
                beginTime: moment().format('YYYY-MM-DD'),
                endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
                timeRangeType: '0',
            },
            //
            equipmentUtilizationDataList: {},
            equipmentSystemStateDataList: {},
            equipmentEnergyData: {},
            historyWorkTimeData: [],
            //
            allSendFinished1: 0,
            allSendFinished2: 0,
            allSendFinished3: 0,
            allSendFinished4: 0,
        }
    }

    getData() {
        this.getEquipmentUtilizationDataList.send({
            params: this.state.timeQuantum
        }).then(res => {
            // console.log(res)
            this.setState({
                equipmentUtilizationDataList: res,
                allSendFinished1: 1,
            })
        })

        this.getEquipmentSystemStateDataList.send().then(res => {
            // console.log(res)
            this.setState({
                equipmentSystemStateDataList: res,
                allSendFinished2: 1,
            })
        })

        this.getEquipmentEnergyData.send({
            params: this.state.timeQuantum
        }).then(res => {
            // console.log(res)
            this.setState({
                equipmentEnergyData: res,
                allSendFinished3: 1,
            })
        })

        new Request(API.GET_HISTORY_WORK_TIME_DATA + '' + this.state.choiceNum).send().then(res => {
            // console.log(res)
            this.setState({
                historyWorkTimeData: res,
                allSendFinished4: 1,
            })
        })

    }

    onChangeState(data: any) {
        // console.log(data)
        this.setState({
            choiceNum: data.choiceNum,
            timeQuantum: data.timeQuantum,
            allSendFinished1: 0,
            allSendFinished2: 0,
            allSendFinished3: 0,
            allSendFinished4: 0,
        }, () => {
            this.getData();
        })
    }

    handleData1() {
        let data = '0';
        if (this.state.equipmentUtilizationDataList) {
            data = ((this.state.equipmentUtilizationDataList.utilizationTimeMinutes / (this.state.equipmentUtilizationDataList.utilizationTimeMinutes + this.state.equipmentUtilizationDataList.pauseTimeMinutes)) * 100).toFixed(1);
        }
        if (isNaN(Number(data))) {
            data = '0';
        }
        return data;
    }

    handleData2() {
        let data = {
            hourNum: '',
            hourUnit: '',
            minuteNum: '',
            minuteUnit: ''
        }
        if (this.state.equipmentSystemStateDataList) {
            let allTime = Math.floor(this.state.equipmentSystemStateDataList.utilizationTimeMinutes)
            if (this.state.equipmentSystemStateDataList.utilizationTimeMinutes < 60) {
                data.hourNum = '';
                data.hourUnit = '';
                data.minuteNum = allTime.toString();
                data.minuteUnit = '分钟'
            } else {
                data.hourNum = Math.floor(allTime / 60).toString();
                data.hourUnit = '小时';
                data.minuteNum = (allTime - Math.floor(allTime / 60) * 60).toString();
                data.minuteUnit = '分'
            }
        }
        return data;
    }

    handleData3() {
        let data = {
            hourNum: '',
            hourUnit: '',
            minuteNum: '',
            minuteUnit: ''
        }
        if (this.state.equipmentSystemStateDataList) {
            let allTime = Math.floor(this.state.equipmentSystemStateDataList.pauseTimeMinutes)
            if (this.state.equipmentSystemStateDataList.pauseTimeMinutes < 60) {
                data.hourNum = '';
                data.hourUnit = '';
                data.minuteNum = allTime.toString();
                data.minuteUnit = '分钟'
            } else {
                data.hourNum = Math.floor(allTime / 60).toString();
                data.hourUnit = '小时';
                data.minuteNum = (allTime - Math.floor(allTime / 60) * 60).toString();
                data.minuteUnit = '分'
            }
        }
        return data;
    }

    handleData4() {
        let data = {
            unit: '',
            energyNum: ''
        };
        if (this.state.equipmentEnergyData) {
            data.unit = '度';
            data.energyNum = this.state.equipmentEnergyData.totalEnergy;
            if (this.state.equipmentEnergyData.totalEnergy > 999 && this.state.equipmentEnergyData.totalEnergy < 10000) {
                data.unit = '度';
                data.energyNum = Math.floor(this.state.equipmentEnergyData.totalEnergy / 1000) + ',' + this.state.equipmentEnergyData.totalEnergy.toString().substring(this.state.equipmentEnergyData.totalEnergy.toString().length - 3);
            }
            if (this.state.equipmentEnergyData.totalEnergy > 9999) {
                data.unit = '万度';
                data.energyNum = (this.state.equipmentEnergyData.totalEnergy / 10000).toFixed(1);
            }
            return data;
        }
        return data;
    }

    handleData5() {
        let data = '0';
        if (this.state.equipmentEnergyData) {
            if (this.state.equipmentEnergyData.pairEnergy != 0) {
                data = (1 / this.state.equipmentEnergyData.pairEnergy).toFixed(1);
            }
        }
        return data;
    }

    handleData6() {
        let data = ['0', '0'];
        if (this.state.equipmentUtilizationDataList) {
            data[0] = (this.state.equipmentUtilizationDataList.utilizationTimeMinutes / 60).toFixed(0);
            data[1] = (this.state.equipmentUtilizationDataList.pauseTimeMinutes / 60).toFixed(0);
            let data0 = Number((this.state.equipmentUtilizationDataList.utilizationTimeMinutes / 60).toFixed(0));
            let data1 = Number((this.state.equipmentUtilizationDataList.pauseTimeMinutes / 60).toFixed(0));
            data[0] = CommonMethod.handleData1(data0).num;
            data[1] = CommonMethod.handleData1(data1).num;
        }
        return data;
    }

    handleChartsData1() {
        let data: any = {
            dataX: [],
            dataY1: [],
            dataY2: []
        };
        if (this.state.equipmentUtilizationDataList.hourUsages) {
            this.state.equipmentUtilizationDataList.hourUsages.map((item: any) => {
                data.dataX.push(item.timeRangeName);
                data.dataY1.push(item.utilizationHours.toFixed(1));
                data.dataY2.push(item.pauseHours.toFixed(1));
            })
        }
        return data
    }

    handleChartsData2() {
        if (this.state.choiceNum === '1' || this.state.choiceNum === '2') {
            return 8;
        } else {
            return -999;
        }
    }

    getMoment() {
        let beginTime = moment().format('YYYY-MM-DD');
        let endTime = moment().add(1, 'day').format('YYYY-MM-DD');
        switch (this.state.timeQuantum.timeRangeType) {
            case '0':
                beginTime = moment().format('YYYY-MM-DD');
                endTime = moment().add(1, 'day').format('YYYY-MM-DD');
                break;
            case '1':
                beginTime = moment().weekday(1).format('YYYY-MM-DD');
                endTime = moment().weekday(8).format('YYYY-MM-DD');
                break;
            case '2':
                beginTime = moment().startOf("month").format("YYYY-MM-DD");
                endTime = moment().add(1, "month").startOf("month").format("YYYY-MM-DD");
                break;
            case '3':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '4':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '5':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '6':
                beginTime = '2020-01-01';
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            default:
                beginTime = moment().format('YYYY-MM-DD');
                endTime = moment().add(1, 'day').format('YYYY-MM-DD');
        }
        this.setState({
            timeQuantum: {
                beginTime,
                endTime,
                timeRangeType: this.state.timeQuantum.timeRangeType,
            },
        })
    }

    render() {
        let finished = false;
        if (this.state.allSendFinished1 && this.state.allSendFinished2 && this.state.allSendFinished3 && this.state.allSendFinished4) {
            finished = true
        }
        return (
            <div className="equipment-container">
                <DetailsHeader>设备</DetailsHeader>
                <DetailsHeaderTab
                    choice={this.state.choiceNum}
                    onClicked={this.onChangeState.bind(this)}
                    isShow={!finished}
                ></DetailsHeaderTab>
                <div className={classNames(
                    {
                        'loading-content': true
                    },
                    {
                        'isShowLoading': finished
                    }
                )}>
                    <div className="loading-container"></div>
                </div>
                <div className={classNames(
                    {
                        'equipment-body': true
                    },
                    {
                        'isShowBody': !finished
                    }
                )}>
                    <div className="equipment-left">
                        <div className="equ-left-content">
                            <DetailsInfoCard
                                unit='%'
                                data={this.handleData1()}
                            >{CommonMethod.handleData2(this.state.choiceNum)}稼动率</DetailsInfoCard>
                            <div className="equ-left-content-two">
                                <DetailsInfoCard
                                    data={
                                        this.state.choiceNum === '0' ?
                                            <React.Fragment>
                                                {this.handleData2().hourNum}<span className={classNames(
                                                    {
                                                        'time': this.handleData2().hourUnit != ''
                                                    },
                                                )}>{this.handleData2().hourUnit}</span>
                                                {this.handleData2().minuteNum}<span className="time">{this.handleData2().minuteUnit}</span>
                                            </React.Fragment>
                                            : this.handleData6()[0]
                                    }
                                    unit={
                                        this.state.choiceNum === '0' ?
                                            ''
                                            : '小时'
                                    }
                                >{CommonMethod.handleData2(this.state.choiceNum)}开机时长</DetailsInfoCard>
                            </div>
                            <DetailsInfoCard
                                isBad={this.state.choiceNum === '0' ? (this.handleData3().hourNum != '' || this.handleData3().minuteNum != '0') : this.handleData6()[1] != '0'}
                                data={
                                    this.state.choiceNum === '0' ?
                                        <React.Fragment>
                                            {this.handleData3().hourNum}<span className={classNames(
                                                {
                                                    'time': this.handleData3().hourUnit != ''
                                                },
                                            )}>{this.handleData3().hourUnit}</span>
                                            {this.handleData3().minuteNum}<span className="time">{this.handleData3().minuteUnit}</span>
                                        </React.Fragment>
                                        : this.handleData6()[1]
                                }
                                unit={
                                    this.state.choiceNum === '0' ?
                                        ''
                                        : '小时'
                                }
                            >{CommonMethod.handleData2(this.state.choiceNum)}待机时长</DetailsInfoCard>
                        </div>
                    </div>
                    <div className="equipment-middle">
                        <DetailsHeapBarChart
                            theid='equ-throughStandby'
                            equSpot={true}
                            markLineData={[
                                {
                                    yAxis: this.handleChartsData2(),
                                    name: 'worktime'
                                },
                            ]}
                            size={{ width: 970, height: 371 }}
                            dataX={this.handleChartsData1().dataX}
                            dataY1={this.handleChartsData1().dataY1}
                            dataY2={this.handleChartsData1().dataY2}
                            boundaryGap={this.state.choiceNum === '1' ? ["0", "2"] : ''}
                        >稼动时长(时)/
                        {/* {<div className="detailsHeapBarChart-icon2 throughStandby-icon"></div>} */}
                            {<span className="standby-title">待机时长(时)</span>}</DetailsHeapBarChart>
                        <div className="equ-middle-bottom">
                            <DetailsLineChart
                                theid='equ-rate'
                                dataY={CommonMethod.handleData3(this.state.equipmentUtilizationDataList.chartModels).dataY}
                                dataX={CommonMethod.handleData3(this.state.equipmentUtilizationDataList.chartModels).dataX}
                                gridRight='7%'
                                size={{ width: 480, height: 371 }}
                            >稼动率(%)</DetailsLineChart>
                            <DetailsLineChart
                                theid='equ-energy'
                                dataY={CommonMethod.handleData3(this.state.equipmentEnergyData.chartModels).dataY}
                                dataX={CommonMethod.handleData3(this.state.equipmentEnergyData.chartModels).dataX}
                                gridRight='7%'
                                size={{ width: 480, height: 371 }}
                            >能耗(度)</DetailsLineChart>
                        </div>
                    </div>
                    <div className="equipment-right">
                        <div className="equ-left-content">
                            <DetailsInfoCard
                                isBad={this.handleData4().energyNum != '0'}
                                unit={this.handleData4().unit}
                                data={this.handleData4().energyNum}
                            >{CommonMethod.handleData2(this.state.choiceNum)}能耗</DetailsInfoCard>
                            <div className="equ-left-content-two">
                                <DetailsInfoCard
                                    unit='双/度'
                                    data={this.handleData5()}
                                >{CommonMethod.handleData2(this.state.choiceNum)}能源利用率</DetailsInfoCard>
                            </div>
                            <DetailsInfoCard
                                unit='Kw'
                                data={this.state.equipmentSystemStateDataList.power ? this.state.equipmentSystemStateDataList.power : 0}
                            >当前功率</DetailsInfoCard>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getData();
        this.timer1 = setInterval(() => {
            this.getData();
            this.getMoment();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1)
        }
    }
}

export default Equipment;