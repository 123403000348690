import React from 'react';
import echarts from 'echarts';
import './DetailsTreeMapChart.scss';
import classNames from 'classnames';

class DetailsTreeMapChart extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    generatingCharts() {
        if (document.getElementById(this.props.theid) != null) {

            let myChart = echarts.init(document.getElementById(this.props.theid) as HTMLDivElement);

            myChart.setOption({
                grid: {
                    left: '0%',
                    right: this.props.gridRight || '4%',
                    top: '10%',
                    bottom: '6%',
                    containLabel: true
                },
                series: [{
                    type: 'treemap',
                    data: this.props.data,
                    breadcrumb: { show: false }
                }]
            });
        }
    }

    render() {
        this.generatingCharts();
        return (
            <div className="detailsTreeMapChart-container">
                <div className="detailsTreeMapChart-header">
                    <div className={classNames(
                        {
                            'detailsTreeMapChart-icon': !this.props.defect
                        },
                        {
                            'detailsTreeMapChart-icon2': this.props.defect
                        }
                    )}></div>
                    <div className="detailsTreeMapChart-title">{this.props.children}</div>
                </div>
                <div id={this.props.theid} style={{ width: this.props.size.width, height: this.props.size.height }}></div>
            </div>
        )
    }
}

export default DetailsTreeMapChart;