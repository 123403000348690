import React, { Component } from 'react'
import DetaislHeader from '../../components/detailsHeader/DetailsHeader';
import VideoPlayer from '../../components/liveVideo/VideoPlayer';


interface Props {
  
}
interface State {
  
}

export  default class ProductionLineLive extends Component<Props, State> {
  state = {}

  render() {
    return (
      <div>
        <DetaislHeader>线首</DetaislHeader>
        <VideoPlayer src='https://hls01open.ys7.com/openlive/e8e1931cf26a40d4b99d9f809ac80116.hd.m3u8' ></VideoPlayer>
      </div>
    )
  }
}



