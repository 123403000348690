import React from 'react';
import './Live.scss';
import Request from '../../api/request';
import API from '../../api/api';
import moment from 'moment';
import DetailsHeader from '../../components/detailsHeader/DetailsHeader';
import LiveEquipment from '../../components/liveEquipment/LiveEquipment';
import LiveVideo from '../../components/liveVideo/LiveVideo';
import LiveTesting from '../../components/liveTesting/LiveTesting';
import LiveQuality from '../../components/liveQuality/LiveQuality';
import CommonMethod from '../../commonMethod/CommonMethod.js';
import classnames from 'classnames'

class Live extends React.Component<any, any> {
  timer1!: NodeJS.Timeout;
  timer2!: NodeJS.Timeout;
  timer3!: NodeJS.Timeout;
  timer4!: NodeJS.Timeout;

  constructor(props: any) {
    super(props);
    this.state = {
      choiceNum: '0',
      timeQuantum: {
        beginTime: moment().format('YYYY-MM-DD'),
        endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
        timeRangeType: '0',
      },
      //
      robotDataList: {},
      informationDataList: {},
      productionThroughDataList: {},
      //

      //this.props.match.params.id
      headerTitle: ['打磨工位1', '处理剂工位1', '处理剂工位2', '涂胶工位1', '涂胶工位2'],
      videoUrl: [
        'https://hls01open.ys7.com/openlive/b5f176934ae14b4f9c8c6f2aa08a94e7.hd.m3u8',
        'https://hls01open.ys7.com/openlive/4ef6160724374a1386444f5fad34a6c0.hd.m3u8',
        'https://hls01open.ys7.com/openlive/c1482396e6ff40538b7209fff389ec15.hd.m3u8',
        'https://hls01open.ys7.com/openlive/f4dd257036354291ab2e1b44f92fecc9.hd.m3u8',
        'https://hls01open.ys7.com/openlive/253839a82d6f480e9e0256e4c810a643.hd.m3u8'
      ],
      //
      allSendFinished1: 0,
    }
  }

  getData() {
    {
      new Request(API.GET_PRODUCTION_THROUGH_DATA).send({
        params: {
          beginTime: this.state.timeQuantum.beginTime,
          endTime: this.state.timeQuantum.endTime,
          timeRangeType: this.state.timeQuantum.timeRangeType,
          robotCode: (Number(this.props.match.params.id) + 1)
        }
      }).then(res => {
        // console.log('getData',res)
        this.setState({
          productionThroughDataList: res,
          allSendFinished1: 1,
        })
      })
    }
  }

  getData2() {
    {
      new Request(API.GET_ROBOT_DATA + (Number(this.props.match.params.id) + 1)).send().then(res => {
        // console.log('getData2',res)
        this.setState({
          robotDataList: res,
        })
      })
    }
  }

  getData3() {
    {
      new Request(API.GET_2D_TESTING + (Number(this.props.match.params.id) + 1)).send().then(res => {
        // console.log(res)
        this.setState({
          informationDataList: res,
        })
      })
    }
  }

  onChangeState(data: any) {
    console.log(data)
    this.setState({
      choiceNum: data.choiceNum,
      timeQuantum: data.timeQuantum,
      allSendFinished1: 0,
    }, () => {
      this.getData();
    })
  }

  handleLiveEquipment() {
    if (this.state.robotDataList) {
      return this.state.robotDataList
    }
    return 0;
  }

  handleLiveTesting() {
    if (this.state.informationDataList) {
      return this.state.informationDataList
    }
    return 0;
  }

  handleData1() {
    let data = {
      throughRate: '0',
      goodProductionCount: '0',
      unit0: '次',
      overPolishedCount: '0',
      unit1: '次',
      polishedTooSmallCount: '0',
      unit2: '次',
      coatingMachineBreakGlueCount: '0',
      unit3: '次',
      coatingMachineSpillGlueCount: '0',
      unit4: '次',
      gelatinizeBreakGlueCount: '0',
      unit5: '次',
      gelatinizeSpillGlueCount: '0',
      unit6: '次',
    };
    if (this.state.productionThroughDataList) {
      data.throughRate = this.state.productionThroughDataList.throughRate >= 1 ? (this.state.productionThroughDataList.throughRate * 100).toString() : (this.state.productionThroughDataList.throughRate * 100).toFixed(1);
      if (this.state.productionThroughDataList.throughRate === 0) { data.throughRate = '0' }

      let Re0 = CommonMethod.handleData1(this.state.productionThroughDataList.goodProductionCount, '次');
      data.goodProductionCount = Re0.num;
      data.unit0 = Re0.unit;

      let Re1 = CommonMethod.handleData1(this.state.productionThroughDataList.overPolishedCount, '次');
      data.overPolishedCount = Re1.num;
      data.unit1 = Re1.unit;

      let Re2 = CommonMethod.handleData1(this.state.productionThroughDataList.polishedTooSmallCount, '次');
      data.polishedTooSmallCount = Re2.num;
      data.unit2 = Re2.unit;

      let Re3 = CommonMethod.handleData1(this.state.productionThroughDataList.coatingMachineBreakGlueCount, '次');
      data.coatingMachineBreakGlueCount = Re3.num;
      data.unit3 = Re3.unit;

      let Re4 = CommonMethod.handleData1(this.state.productionThroughDataList.coatingMachineSpillGlueCount, '次');
      data.coatingMachineSpillGlueCount = Re4.num;
      data.unit4 = Re4.unit;

      let Re5 = CommonMethod.handleData1(this.state.productionThroughDataList.gelatinizeBreakGlueCount, '次');
      data.gelatinizeBreakGlueCount = Re5.num;
      data.unit5 = Re5.unit;

      let Re6 = CommonMethod.handleData1(this.state.productionThroughDataList.gelatinizeSpillGlueCount, '次');
      data.gelatinizeSpillGlueCount = Re6.num;
      data.unit6 = Re6.unit;
    }
    // console.log(data)
    return data;
  }

  getMoment() {
    {
      let beginTime = moment().format('YYYY-MM-DD');
      let endTime = moment().add(1, 'day').format('YYYY-MM-DD');
      switch (this.state.timeQuantum.timeRangeType) {
        case '0':
          beginTime = moment().format('YYYY-MM-DD');
          endTime = moment().add(1, 'day').format('YYYY-MM-DD');
          break;
        case '1':
          beginTime = moment().weekday(1).format('YYYY-MM-DD');
          endTime = moment().weekday(8).format('YYYY-MM-DD');
          break;
        case '2':
          beginTime = moment().startOf("month").format("YYYY-MM-DD");
          endTime = moment().add(1, "month").startOf("month").format("YYYY-MM-DD");
          break;
        case '3':
          beginTime = moment().startOf("year").format("YYYY-MM-DD");
          endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
          break;
        case '4':
          beginTime = moment().startOf("year").format("YYYY-MM-DD");
          endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
          break;
        case '5':
          beginTime = moment().startOf("year").format("YYYY-MM-DD");
          endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
          break;
        case '6':
          beginTime = '2020-01-01';
          endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
          break;
        default:
          beginTime = moment().format('YYYY-MM-DD');
          endTime = moment().add(1, 'day').format('YYYY-MM-DD');
      }
      this.setState({
        timeQuantum: {
          beginTime,
          endTime,
          timeRangeType: this.state.timeQuantum.timeRangeType,
        },
      })
    }
  }

  render() {
    let pageId = this.props.match.params.id;
    let finished = false;
    if (this.state.allSendFinished1) {
      finished = true
    }
    return (
      <div className="live-container">
        <DetailsHeader>{this.state.headerTitle[pageId]}</DetailsHeader>
        <div className={classnames({
          'live-body': true,
          'ipad-live-body': (window as any).isIpad
        })}>
          <div className="live-left">
            <LiveQuality
              onClicked={this.onChangeState.bind(this)}
              choice={this.state.choiceNum}
              data={this.handleData1()}
              chartsData={CommonMethod.handleData3(this.state.productionThroughDataList.chartModels)}
              pageId={pageId}
              isShow={!finished}
            ></LiveQuality>
          </div>
          <div className="live-middle">
            <div className="live-middle-top">
              <LiveVideo data={this.state.videoUrl[pageId]}></LiveVideo>
            </div>
            <div className="live-middle-bottom">
              <LiveEquipment data={this.handleLiveEquipment()}></LiveEquipment>
            </div>
          </div>
          <div className="live-right">
            <LiveTesting data={this.handleLiveTesting()}></LiveTesting>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.getData();
    this.getData2();
    this.getData3();
    this.timer1 = setInterval(() => {
      this.getData();
    }, 1000 * 5)
    this.timer2 = setInterval(() => {
      this.getData2();
    }, 1000 * 10)
    this.timer3 = setInterval(() => {
      this.getData3();
    }, 1000 * 5)
    this.timer4 = setInterval(() => {
      this.getMoment();
    }, 1000 * 10)
  }

  componentWillUnmount() {
    if (this.timer1 != null) {
      clearInterval(this.timer1)
    }
    if (this.timer2 != null) {
      clearInterval(this.timer2)
    }
    if (this.timer3 != null) {
      clearInterval(this.timer3)
    }
    if (this.timer4 != null) {
      clearInterval(this.timer4)
    }
  }

}

export default Live;