import React from 'react';
import './HomeHeader.scss';
import moment from 'moment'
import classnames from 'classnames'

class HomeHeader extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            timer: {},
            date: new Date()
        }
    }

    render() {
    

        return (
            <div className={classnames({
                'homeheader-container':true,
                'ipad':(window as any).isIpad
            })}  >
                <div className="title">
                    ZS-TEK  智能化产线
                    </div>
                <div className="clock">
                     <div className="clock-image"></div>
                    <span className="clock-word">{moment().format('YYYY/MM/DD HH:mm:ss')}</span>
                </div>
               
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            timer: setInterval(() => {
                this.setState({ date: new Date() })
            }, 1000)
        })
    }

    componentWillUnmount() {
        if (this.state.timer != null) {
            clearInterval(this.state.timer);
        }
    }
}

export default HomeHeader;