import React from 'react';
import classnames from 'classnames'
import './StateCard.scss';
import { Link } from 'react-router-dom';
interface Props {
    title?:string,
    toPage?:any,
    status?:boolean
}
class StateCard extends React.Component<Props> {
    render() {
        return (
            <div className="statecard-container" >
                <Link to={{ pathname: this.props.toPage }}>
                    <div className="statecard-content">
                        <div className="statecard-title">{this.props.title}</div>
                        <span className="statecard-unit">设备状态</span>
                        <span  className ={classnames({
                            'statecard-data':true,
                            'error':!this.props.status
                        })}>
                            {this.props.status?'正常':'异常'}
                        </span>
                    </div>
                </Link>
            </div>
        )
    }
}

export default StateCard;