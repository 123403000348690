import React from 'react';
import './DetailsHeaderTab.scss';
import classNames from 'classnames';
import moment from 'moment';

class DetailsHeaderTab extends React.Component<any, any> {
    render() {
        return (
            <div className={classNames(
                {
                    'detailsHeaderTab-container': true,
                    'ipad-tab': (window as any).isIpad
                },
                {
                    'isShow': this.props.isShow
                }
            )}
            >
                <div className="detailsHeaderTab-content">
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '0'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '0',
                            timeQuantum: {
                                beginTime: moment().format('YYYY-MM-DD'),
                                endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
                                timeRangeType: '0',
                            }
                        })}
                    >今日</span>
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '1'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '1',
                            timeQuantum: {
                                beginTime: moment().weekday(1).format('YYYY-MM-DD'),
                                endTime: moment().weekday(8).format('YYYY-MM-DD'),
                                timeRangeType: '1',
                            }
                        })}
                    >本周</span>
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '2'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '2',
                            timeQuantum: {
                                beginTime: moment().startOf("month").format("YYYY-MM-DD"),
                                endTime: moment().add(1, "month").startOf("month").format("YYYY-MM-DD"),
                                timeRangeType: '2',
                            }
                        })}
                    >本月</span>
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '3'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '3',
                            timeQuantum: {
                                beginTime: moment().startOf("year").format("YYYY-MM-DD"),
                                endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                timeRangeType: '3',
                            }
                        })}
                    >今年(按周)</span>
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '4'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '4',
                            timeQuantum: {
                                beginTime: moment().startOf("year").format("YYYY-MM-DD"),
                                endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                timeRangeType: '4',
                            }
                        })}
                    >今年(按月)</span>
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '5'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '5',
                            timeQuantum: {
                                beginTime: moment().startOf("year").format("YYYY-MM-DD"),
                                endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                timeRangeType: '5',
                            }
                        })}
                    >今年(按季度)</span>
                    <span
                        className={classNames(
                            {
                                'choice': this.props.choice === '6'
                            },
                        )}
                        onClick={() => this.props.onClicked({
                            choiceNum: '6',
                            timeQuantum: {
                                beginTime: '2020-01-01',
                                endTime: moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
                                timeRangeType: '6',
                            }
                        })}
                    >累积</span>
                </div>
                <div className="detailsHeaderTab-export">
                    <div className="detailsHeaderTab-icon"></div>
                    <div className="detailsHeaderTab-title">导出</div>
                </div>
            </div>
        )
    }
}

export default DetailsHeaderTab;

// let now = moment().format('YYYY-MM-DD');
// let now = moment().add(1, 'day').format('YYYY-MM-DD');//今日

// let now = moment().weekday(1).format('YYYY-MM-DD');
// let now = moment().weekday(8).format('YYYY-MM-DD');//本周


// let now = moment().startOf("month").format("YYYY-MM-DD");
// let now = moment().add(1, "month").startOf("month").format("YYYY-MM-DD");//本月

// let now = moment().startOf("year").format("YYYY-MM-DD");
// let now = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");//今年

// '2019-01-01'
// let now = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");//累积

// console.log(this.state.timeQuantum);