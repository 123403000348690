import React from 'react';
import './LiveTesting.scss';
import classNames from 'classnames';

class LiveTesting extends React.Component<any, any> {
    render() {
        let imageUrl = '';
        if (this.props.data.imageUrl) {
            imageUrl = (window.location.hostname.substring(0, 3) === '192' ?
                'http://192.168.1.3:11003/'
                :
                'http://api.a2.zs-tek.com/') + this.props.data.imageUrl;
        }
        let position = '';
        if (this.props.data.shoeLeftAndRight != undefined) {
            position = (this.props.data.shoeLeftAndRight === true ? '左脚' : "右脚");
        }
        let isSuccess = '';
        if (this.props.data.success != undefined) {
            isSuccess = (this.props.data.success === true ? 'OK' : "NG");
        }

        return (
            <React.Fragment>
                <div className="liveTesting-container">
                    <div className="liveTesting-title">实时品质检测</div>
                    <div className="liveTesting-content">
                        <div className="liveTesting-content-left">
                            <div className="liveTesting-left-title">
                                鞋型
                                <span>{this.props.data.shoeTypeName}</span>
                                <span className="liveTesting-position">{position}</span>
                            </div>
                            <div className="liveTesting-left-title">
                                尺码
                                <span>{this.props.data.shoeSize}</span>
                            </div>
                            <div className="liveTesting-left-title">
                                检测结果
                                <span className={classNames(
                                    {
                                        'liveTesting-success': true
                                    },
                                    {
                                        'liveTesting-error': isSuccess === 'NG' ? true : false
                                    }
                                )}>{isSuccess}</span>
                            </div>
                        </div>
                        <div className="liveTesting-content-right">
                            <div
                                className="liveTesting-image"
                                style={{ backgroundImage: `url(${imageUrl})` }}
                            ></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LiveTesting;