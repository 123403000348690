import React from 'react';
import './Production.scss';
import Request from '../../api/request';
import API from '../../api/api';
import moment from 'moment';
import classNames from 'classnames';
import DetailsHeader from '../../components/detailsHeader/DetailsHeader';
import DetailsHeaderTab from '../../components/detailsHeaderTab/DetailsHeaderTab';
import DetailsInfoCard from '../../components/detailsInfoCard/DetailsInfoCard';
import DetailsBarChart from '../../components/charts/detailsBarChart/DetailsBarChart';
import DetailsLineChart from '../../components/charts/detailsLineChart/DetailsLineChart';
import DetailsTreeMapChart from '../../components/charts/detailsTreeMapChart/DetailsTreeMapChart';
import CommonMethod from '../../commonMethod/CommonMethod.js';

class Production extends React.Component<any, any> {
    private getProductionCountDataList: Request;
    private getProductionBeatDataList: Request;
    private getProductionShoeTypeDataList: Request;
    //
    timer1!: NodeJS.Timeout;

    constructor(props: any) {
        super(props);
        this.getProductionCountDataList = new Request(API.GET_PRODUCTION_COUNT_DATA);
        this.getProductionBeatDataList = new Request(API.GET_PRODUCTION_BEAT_DATA);
        this.getProductionShoeTypeDataList = new Request(API.GET_PRODUCTION_SHOE_TYPE_DATA);
        //
        this.state = {
            choiceNum: '0',
            timeQuantum: {
                beginTime: moment().format('YYYY-MM-DD'),
                endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
                timeRangeType: '0',
            },
            
            productionCountDataList: {},
            productionBeatDataList: {},
            productionShoeTypeDataList: {},
            
            productionCount: 0,
            aPairOfSeconds: 0,
            hourAFewPairs: 0,
            shoeTypeCount: 0,
            
            allSendFinished1: 0,
            allSendFinished2: 0,
            allSendFinished3: 0,
        }
    }

    getData() {
        this.getProductionCountDataList.send({
            params: this.state.timeQuantum
        }).then(res => {
            this.setState({
                productionCountDataList: res
            }, () => {
                this.setState({
                    productionCount: this.state.productionCountDataList.productionCount,
                    allSendFinished1: 1,
                })
            })
        })

        this.getProductionBeatDataList.send({
            params: this.state.timeQuantum
        }).then(res => {
            // console.log(res)
            this.setState({
                productionBeatDataList: res
            }, () => {
                this.setState({
                    aPairOfSeconds: this.state.productionBeatDataList.aPairOfSeconds,
                    hourAFewPairs: this.state.productionBeatDataList.hourAFewPairs,
                    allSendFinished2: 1,
                })
            })
        })

        this.getProductionShoeTypeDataList.send({
            params: this.state.timeQuantum
        }).then(res => {
            // console.log(res)
            this.setState({
                productionShoeTypeDataList: res
            }, () => {
                this.setState({
                    shoeTypeCount: this.state.productionShoeTypeDataList.shoeTypeCount,
                    allSendFinished3: 1,
                })
            })
        })
    }

    onChangeState(data: any) {
        // console.log(data)
        this.setState({
            choiceNum: data.choiceNum,
            timeQuantum: data.timeQuantum,
            allSendFinished1: 0,
            allSendFinished2: 0,
            allSendFinished3: 0,
        }, () => {
            this.getData();
        })
    }

    handleData2() {
        let data = {
            aPairOfSeconds: 0,
            hourAFewPairs: 0
        };
        if (this.state.aPairOfSeconds === 0 || this.state.aPairOfSeconds) {
            data.aPairOfSeconds = this.state.aPairOfSeconds.toFixed(0);
        }
        if (this.state.hourAFewPairs === 0 || this.state.hourAFewPairs) {
            data.hourAFewPairs = Math.floor(this.state.hourAFewPairs);
        }
        return data;
    }

    handleChartsData2() {
        let data: any = {
            dataX: [],
            dataY: []
        };
        if (this.state.productionBeatDataList.chartModels) {
            this.state.productionBeatDataList.chartModels.map((item: any) => {
                data.dataX.push(item.key)
                if (item.value != null)
                    if (item.value === 0) {
                        data.dataY.push(item.value);
                    } else {
                        data.dataY.push((3600 / item.value).toFixed(0));
                    }
            })
            return data;
        }
        return data;
    }

    handleChartsData3() {
        let data = [{
            name: '暂无数据',
            value: 1,
        }]
        if (this.state.productionShoeTypeDataList.chartModels && this.state.productionShoeTypeDataList.chartModels.length != 0) {
            let newData: { name: any; value: any; }[] = []
            this.state.productionShoeTypeDataList.chartModels.map((item: any) => {
                let subData = {
                    name: item.key,
                    value: item.value
                }
                newData.push(subData);
            })
            data = newData;
        }
        // console.log(data)
        return data;
    }

    getMoment() {
        let beginTime = moment().format('YYYY-MM-DD');
        let endTime = moment().add(1, 'day').format('YYYY-MM-DD');
        switch (this.state.timeQuantum.timeRangeType) {
            case '0':
                beginTime = moment().format('YYYY-MM-DD');
                endTime = moment().add(1, 'day').format('YYYY-MM-DD');
                break;
            case '1':
                beginTime = moment().weekday(1).format('YYYY-MM-DD');
                endTime = moment().weekday(8).format('YYYY-MM-DD');
                break;
            case '2':
                beginTime = moment().startOf("month").format("YYYY-MM-DD");
                endTime = moment().add(1, "month").startOf("month").format("YYYY-MM-DD");
                break;
            case '3':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '4':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '5':
                beginTime = moment().startOf("year").format("YYYY-MM-DD");
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            case '6':
                beginTime = '2020-01-01';
                endTime = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
                break;
            default:
                beginTime = moment().format('YYYY-MM-DD');
                endTime = moment().add(1, 'day').format('YYYY-MM-DD');
        }
        this.setState({
            timeQuantum: {
                beginTime,
                endTime,
                timeRangeType: this.state.timeQuantum.timeRangeType,
            },
        })
    }

    render() {
        let finished = false;
        if (this.state.allSendFinished1 && this.state.allSendFinished2 && this.state.allSendFinished3) {
            finished = true
        }
        return (
            <div className="production-container">
                <DetailsHeader>生产</DetailsHeader>
                <DetailsHeaderTab
                    choice={this.state.choiceNum}
                    onClicked={this.onChangeState.bind(this)}
                    isShow={!finished}
                ></DetailsHeaderTab>
                <div className={classNames(
                    {
                        'loading-content': true
                    },
                    {
                        'isShowLoading': finished
                    }
                )}>
                    <div className="loading-container"></div>
                </div>
                <div className={classNames(
                    {
                        'production-body': true
                    },
                    {
                        'isShowBody': !finished
                    }
                )}>
                    <div className="production-left">
                        <div className="left-productionName">
                            <DetailsInfoCard
                                unit={CommonMethod.handleData1(this.state.productionCount, '双').unit}
                                // unit={this.handleData1().unit}
                                data={CommonMethod.handleData1(this.state.productionCount, '双').num}
                            // data={this.handleData1().productionCount}
                            >{CommonMethod.handleData2(this.state.choiceNum)}产量</DetailsInfoCard>
                        </div>
                        <DetailsInfoCard
                            unit='秒/双'
                            data={this.handleData2().aPairOfSeconds}
                        >生产节拍</DetailsInfoCard>
                        <DetailsInfoCard
                            unit='双/小时'
                            data={this.handleData2().hourAFewPairs}
                        >平均产能</DetailsInfoCard>
                        <DetailsInfoCard
                            unit='款'
                            data={this.state.shoeTypeCount}
                        >上线鞋型</DetailsInfoCard>
                    </div>
                    <div className="production-right">
                        <DetailsBarChart
                            theid='pro-productionCount'
                            dataY={CommonMethod.handleData3(this.state.productionCountDataList.chartModels).dataY}
                            dataX={CommonMethod.handleData3(this.state.productionCountDataList.chartModels).dataX}
                            size={{ width: 1300, height: 400 }}
                        >产量(双)</DetailsBarChart>
                        <div className="pro-right-bottom">
                            <DetailsLineChart
                                theid='pro-hourAFewPairs'
                                dataY={this.handleChartsData2().dataY}
                                dataX={this.handleChartsData2().dataX}
                                gridRight={'8%'}
                                fontsize={16}
                                size={{ width: 600, height: 350 }}
                            >平均产能(双/小时)</DetailsLineChart>
                            <DetailsTreeMapChart
                                theid='pro-treeMap'
                                data={this.handleChartsData3()}
                                gridRight={'8%'}
                                fontsize={16}
                                size={{ width: 700, height: 330 }}
                            >柔性化分析</DetailsTreeMapChart>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getData();
        this.timer1 = setInterval(() => {
            this.getData();
            this.getMoment();
        }, 1000 * 60)
    }

    componentWillUnmount() {
        if (this.timer1 != null) {
            clearInterval(this.timer1)
        }
    }
}

export default Production;