import React from 'react';
import HomeHeader from '../../components/homeHeader/HomeHeader';
import HomeBody from '../../components/homeBody/HomeBody';
import HomeFooter from '../../components/homeFooter/HomeFooter';
import './Home.scss';
import CommonMethod from '../../commonMethod/CommonMethod.js';

class Home extends React.Component<any, any> {
    render() {
        return (
            <div className="home-container">
                <HomeHeader></HomeHeader>
                <HomeBody history={this.props.history}></HomeBody>
                <HomeFooter></HomeFooter>
            </div>
        )
    }
}

export default Home;