import React from 'react';
import echarts from 'echarts';
import './DetailsBarChart.scss';
import classNames from 'classnames';

class DetailsBarChart extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    generatingCharts() {
        if (document.getElementById(this.props.theid) != null) {

            let myChart = echarts.init(document.getElementById(this.props.theid) as HTMLDivElement);

            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['产量'],
                    show: false
                },
                toolbox: {
                    feature: {
                        // saveAsImage: {}
                    }
                },
                grid: {
                    left: '0%',
                    right: '4%',
                    top: '10%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.props.dataX,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#233b51'
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 20
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#233b51',
                                opacity: 0.5
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#233b51'
                            }
                        },
                        axisLabel: {
                            color: '#fff',
                            fontSize: 20
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#233b51',
                                opacity: 0.5
                            }
                        }
                    },
                ],
                dataZoom: [{
                    type: "inside"         //详细配置可见echarts官网
                }],
                series: [{
                    data: this.props.dataY,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            label: {
                                show: false,      //开启显示
                                position: 'top', //在上方显示
                                textStyle: {     //数值样式
                                    color: '#fff',
                                    fontSize: 12
                                }
                            },
                            color: '#3680f8',
                        }
                    }
                }]
            });
        }
    }

    render() {
        this.generatingCharts();
        return (
            <div className="detailsBarChart-container">
                <div className="detailsBarChart-header">
                    <div className={classNames(
                        {
                            'detailsBarChart-icon': !this.props.defect
                        },
                        {
                            'detailsBarChart-icon2': this.props.defect
                        }
                    )}></div>
                    <div className="detailsBarChart-title">{this.props.children}</div>
                </div>
                <div id={this.props.theid} style={{ width: this.props.size.width, height: this.props.size.height }}></div>
            </div>
        )
    }
}

export default DetailsBarChart;