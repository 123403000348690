import React from 'react';
import './HomeQR.scss';

interface Props {
    list: Array<any>
}

class HomeQR extends React.Component<Props> {
    render() {
        return (
            <div className="homeqr-container">
                {
                    this.props.list.map((item: any, idx: number) => {
                        return (
                            <div key={idx} className="icon">
                                <div style={{backgroundImage: `url(${item.icon})`}} className="icon-image"></div>
                                <span className="icon-title">{item.title}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default HomeQR;