const adaption = () => {
    let htmlElement = document.querySelector("html") as HTMLHtmlElement;
    // let sc = getAll.clientWidth / 1920;
    let scW = htmlElement.clientWidth / 1920;
    let scH = htmlElement.clientHeight / 1080;
    let rootElement = document.querySelector('#root') as HTMLDivElement;
    // rootElement.style.transform = `scaleX(${scW}) scaleY(${scH})`;

    if ((window as any).isIpad) {
        rootElement.style.transform = `scaleX(${scW}) scaleY(${scH * 0.76})`;
    } else {
        rootElement.style.transform = `scaleX(${scW}) scaleY(${scH})`;

    }

    // if ((window as any).isIpad) {
    //     (getDom as HTMLElement).style.transform = `scaleX(${scW}) scaleY(${scW})`;
    //     (getDom as HTMLElement).style.marginTop = `${(getAll.clientHeight - 1080 * scW) / 2}px`;
    // } else {
    //     (getDom as HTMLElement).style.transform = `scaleX(${scW}) scaleY(${scH})`;
    //     // getDom.style.transform = `scale(${sc})`;
    // }
};
export default adaption;
